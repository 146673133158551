import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { componentGroupsState, OrderPayload } from 'pages/reference-implementation/provisioning/RI004O/store';
import { platformManaged, selectedEnv } from 'pages/reference-implementation/provisioning/RI004O/utils/constant';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { getComponentsFromSchema } from '../../utils';

export const Division = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const componentGroups = useRecoilValue(componentGroupsState);
    const [options, setOptions] = React.useState([]);
    const divisionApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.sectors}/${order?.sector}?platformManaged=${platformManaged}`));

    const setDivision = (division) => {
        setOrder({
            ...order,
            division,
            program: null,
            resourceMgmt: {
                [selectedEnv]: {
                    components: getComponentsFromSchema({ componentGroups, particularPrompt: ['mandatoryComponents'] })?.[0]?.components || [],
                },
            },
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (division) => options.find((option) => division === option.value);

    const handleOnSelectChange = (selected) => setDivision(selected.value);

    React.useEffect(() => {
        if (divisionApi?.isSuccess) {
            setOptions(divisionApi?.data?.data?.divisions?.map((app) => ({ label: app?.divisionDisplayName, value: app?.divisionDisplayName })));
        }
    }, [divisionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.division) {
            setOrder({ ...order, division: options?.[0].value });
        }
        if (options && options?.length && order?.division) {
            const division = isExistsInOptions(order?.division);
            setOrder({ ...order, division: division?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        divisionApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.division && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.division, order?.isTouched]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="division"
                label={<InputLabel label="Division" isRequired />}
                value={order?.division || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={divisionApi.isLoading}
                isLoading={divisionApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(Division);
