import React from 'react';
import { useRecoilValue } from 'recoil';
import { OrderPayload } from 'pages/sso-okta/store';
import SAMLForm from './SAMLForm';
import OIDCForm from './OIDCForm';
import Machine2MachineForm from './Machine2MachineForm';
import { SAMLRequestType, OIDCRequestType } from './utils/constant';

const SsoForm = () => {
    const order = useRecoilValue(OrderPayload);
    if (order?.requestType === SAMLRequestType) {
        return <SAMLForm />;
    }
    if (order?.requestType === OIDCRequestType) {
        return <OIDCForm />;
    }
    return <Machine2MachineForm />;
};

export default SsoForm;
