import { Box, Divider, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import NsgAccordion from 'pages/provisioning/nsg-automation-rules/components/accordion';
import Styles from 'pages/provisioning/nsg-automation-rules/style';
import LabelValue from 'pages/provisioning/nsg-automation-rules/components/label-value';

const MultipleResourceNsg = (props) => {
    const { resources, defaultExpanded, isLastPanelOpen, isOrderPage, isProvisionSection } = props;
    return (
        <Box>
            {Object.entries(resources)?.map(([key, value], index) => (
                <React.Fragment key={`main-res-${index}`}>
                    <NsgAccordion
                        title={key}
                        key={`ri-resource-${index}`}
                        defaultExpanded={defaultExpanded}
                        open={defaultExpanded || (isLastPanelOpen && index === Object.keys(resources).length - 1)}
                        isOrderPage={isOrderPage}
                        isExpandIconML={!isOrderPage}
                    >
                        {value.map((resource, i) => (
                            <React.Fragment key={`sub-res-${index}-${i}`}>
                                <NsgAccordion
                                    title={`${resource?.properties?.personaType} - ${i + 1 < 10 ? `0${i + 1}` : i + 1}`}
                                    key={`ri-sub-resource-${i}`}
                                    defaultExpanded={defaultExpanded}
                                    open={defaultExpanded || i === 0}
                                    isOrderPage={isOrderPage}
                                >
                                    <Box key={`box-sr-${index}-${i}`}>
                                        {(!isOrderPage || isProvisionSection) &&
                                            resource?.properties &&
                                            Object.entries(resource?.properties).map(([propKey, propValue], propertyIndex) => (
                                                <Box
                                                    className="ripreview-details"
                                                    sx={{
                                                        ...Styles?.preview?.resourceProperty,
                                                    }}
                                                    key={`box-sr-${index}-${i}-${propertyIndex}`}
                                                >
                                                    <LabelValue istextTransform={false} width={220} label={propKey} value={propValue} />
                                                </Box>
                                            ))}
                                        <Divider variant="horizontal" sx={{ ...Styles?.preview?.divider, marginTop: 0 }} />
                                        {resource?.properties && isOrderPage && (
                                            <Grid container spacing={2}>
                                                {Object.entries(resource?.properties).map(
                                                    ([configKey, configValue], configIndex) =>
                                                        configValue && (
                                                            <Grid
                                                                item
                                                                xs={isOrderPage ? 12 : 6}
                                                                key={`sub-res-config-${index}-${i}-${configIndex}-${configKey}`}
                                                            >
                                                                <Box className="ripreview-details" key={`box-${configIndex}-${configKey}`}>
                                                                    <LabelValue
                                                                        istextTransform={false}
                                                                        width={220}
                                                                        label={configKey}
                                                                        value={configValue}
                                                                        labelFlex={isOrderPage ? 1 : 2}
                                                                    />
                                                                </Box>
                                                                {/* ))} */}
                                                            </Grid>
                                                        )
                                                )}
                                            </Grid>
                                        )}
                                    </Box>
                                </NsgAccordion>
                            </React.Fragment>
                        ))}
                    </NsgAccordion>
                </React.Fragment>
            ))}
        </Box>
    );
};

MultipleResourceNsg.propTypes = {
    resources: PropTypes.array,
    defaultExpanded: PropTypes.bool,
    isLastPanelOpen: PropTypes.bool,
    isOrderPage: PropTypes.bool,
    isProvisionSection: PropTypes.bool,
};

MultipleResourceNsg.defaultProps = {
    resources: [],
    defaultExpanded: false,
    isLastPanelOpen: true,
    isOrderPage: false,
    isProvisionSection: false,
};

export default MultipleResourceNsg;
