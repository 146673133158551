export const RICONSUMERAPP = {
    Jwt: {
        // provisioning
        previewOrder: 'api/reference-implementation/web-application/2.0/internal/order/ri003o/preview',
        submitOrder: 'api/reference-implementation/web-application/2.0/internal/order/ri003o/submit',
        reSubmit: 'api/reference-implementation/web-application/2.0/internal/order/ri003o/resubmit',
        submitApproval: 'api/reference-implementation/web-application/1.0/internal/order/submit-approval',
        costCalculation: 'api/costs/1.0/ri/cost-calculation',
        internalPreviewOrder: 'api/reference-implementation/web-application/1.0/internal/preview/order',
        downloadTF: 'api/reference-implementation/web-application/1.0/internal/order',
        internalServiceAccount: 'api/day2/1.0/internal/validate-service-account',
        serviceAccountYesValidation: 'api/ri/resource/name/1.0/service-account/validate',
        serviceAccountNoValidation: 'api/day2/1.0/internal/validate-service-account',
    },
};
export default RICONSUMERAPP;
