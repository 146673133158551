import {
    RICatalogCode003,
    RICatalogCode003O,
    RICatalogCode003A,
    RICatalogCode004,
    RICatalogCode004A,
    RICatalogCode005,
} from 'pages/reference-implementation/utils/RICatalogCode';
import { selectedEnv } from './constant';

export const ShowAppUrlBasedOnCatalogCode = [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005];
export const checkIsApplicable = (options) => !(options?.length === 1 && options?.[0]?.value?.toLowerCase() === 'not applicable');
export const isShowAppRegionField = ({ order }) => {
    const sector = order?.sector?.toLowerCase();
    // if (order?.sector && ['amesa']?.includes(sector) && order?.catalogCode === RICatalogCode005) {
    //     return true;
    // }
    if (
        order?.sector &&
        ['cgf', 'amesa', 'snt dps']?.includes(sector) &&
        [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode004, RICatalogCode004A, RICatalogCode005]?.includes(order?.catalogCode)
    ) {
        return true;
    }
    return false;
};
export const isAksnsConfigured = ({ order }) => {
    let configured = false;
    const aksnsOrderComponent = {
        ...order?.resourceMgmt?.[selectedEnv]?.components?.filter(
            (component) => component?.componentId === 'RI003-AKSNS' && component?.groupType === 'mandatoryComponents'
        )?.[0],
    };

    if (aksnsOrderComponent?.configureData && Object?.keys(aksnsOrderComponent?.configureData)?.length > 0) {
        configured = true;
    }
    return configured;
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR/backup services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    catalogCode: null,
    projectId: null,
    projectDetails: null,
    businessUnit: null,
    sector: null,
    program: null,
    appUrl: null,
    appContextPath: null,
    division: null,
    budgetCode: null,
    budgetCodeDetails: null,
    billingInfo: null,
    environment: null,
    appProfile: null,
    appProfileDetails: null,
    dataClassification: null,
    environmentDetails: null,
    businessAppService: 'No',
    businessApp: null,
    appService: null,
    appOwner: null,
    appOwnerDetails: null,
    // certificateOwnerOne: null,
    // certificateOwnerOneDetails: null,
    // certificateOwnerTwo: null,
    // certificateOwnerTwoDetails: null,
    applicationName: null,
    appShortName: null,
    businessContact: null,
    businessContactDetails: null,
    privilegedADGroup: null,
    nonPrivilegedADGroup: null,
    nonPrivilegedADGroupList: [{ readerValue: '' }],
    privilegedADGroupList: [{ contributorValue: '' }],
    privilegedADGroupOwner: null,
    privilegedADGroupOwnerDetails: null,
    nonPrivilegedADGroupOwner: null,
    nonPrivilegedADGroupOwnerDetails: null,
    // appSupportMailId: null,
    isExternal: 'No',
    isTouched: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};

export function transformModifiedComponent(components, componentGroups) {
    const modifiedComponents = componentGroups.flatMap((group) => {
        return group.components.map((component) => {
            const getComponentById = components.find((apiComponent) => apiComponent['component-id'] === component.componentId);
            const configureData =
                getComponentById && Object.keys(getComponentById).length > 1
                    ? Object.fromEntries(Object.entries(getComponentById).filter(([key]) => key !== 'component-id'))
                    : null;
            return configureData ? { ...component, configureData } : { ...component };
        });
    });
    return modifiedComponents;
}

// const getConsumerGrp = (val) => {
//     let res = 'No';
//     if (val !== '') {
//         res = 'Yes';
//     }
//     return res;
// };
// const getConsumerGrpValue = (val) => {
//     let res = [{ value: '' }];
//     if (val !== '') {
//         res = val?.split(',')?.map((t) => ({ value: t }));
//     }
//     return res;
// };
// function getServiceById(components, componentId) {
//     return components.find((component) => component['component-id'] === componentId);
// }
// export const retentionUnits = ['In Days', 'In Hours'];

// export const getRetention = (millisec) => {
//     try {
//         let seconds = Math.floor(millisec / 1000); //   coverting millisec to seconds (Ex.: 5400000/1000 = 5400)
//         let minutes = Math.floor(seconds / 60); //   coverting seconds to minutes (Ex.: 5400/60 = 90)
//         let hours = Math.floor(minutes / 60); //    coverting minutes to hours (Ex.: 90/60 = 1.5 => 1 (due to Math.floor))
//         seconds %= 60; // rounding up remaining seconds by module  (Ex.: 5400%60 = 0)
//         minutes = seconds >= 30 ? minutes + 1 : minutes; //  if seconds greater than 30 adding 1 to minute (Ex.: 0>=30 (false) => 90)
//         minutes %= 60; //  In order to get the exact minutes  (Ex.: 90%60 = 30)
//         hours %= 24; // In order to get the exact hours  (Ex.: 1%24 = 1)
//         return { hours, minutes };
//     } catch (e) {
//         return { hours: 0, minutes: 0 };
//     }
// };
// const getTopicRetention = (retentionUnit, retentionDetails) => {
//     let retentionValue = { retention: '', retentionUnit, retentionDetails };
//     if (retentionUnit === retentionUnits[0]) {
//         const getdays = retentionDetails?.millisec / (1000 * 60 * 60 * 24);
//         retentionValue = { retention: getdays, retentionUnit, retentionDetails: { displayValue: getdays, millisec: retentionDetails?.millisec } };
//     }
//     if (retentionUnit === retentionUnits[1]) {
//         const hourMin = getRetention(retentionDetails?.millisec);
//         retentionValue = {
//             retentionUnit,
//             retention: `${hourMin?.hours}:${hourMin?.minutes}`,
//             retentionDetails: { displayValue: `${hourMin?.hours}:${hourMin?.minutes}`, millisec: retentionDetails?.millisec },
//         };
//     }
//     return retentionValue;
// };
const getSelectedComponentsForEdit = (data, componentGroups, orderComponents) => {
    const componentMap = new Map();
    // Create a map of orderComponents for quick lookup
    orderComponents.forEach((orderComponent) => {
        const componentId = orderComponent['component-id'];
        componentMap.set(componentId, { ...orderComponent });
    });

    const selectedComponents = [];
    // Iterate through each group in componentGroups
    componentGroups.forEach((group) => {
        group.components.forEach((component) => {
            const { componentId } = component;
            const orderComponent = componentMap.get(componentId);
            if (orderComponent) {
                // Initialize the component object to be added to the local state
                const localComponent = { ...component };
                // Assign configureData based on configurationComponentCode
                if (localComponent.configurable && orderComponent) {
                    switch (localComponent.configurationComponentCode) {
                        case 'AZSERVICEBUS':
                            localComponent.configureData = {
                                serviceBusCapacity: orderComponent.size,
                                partitionCount: orderComponent['partition-count'],
                            };
                            break;
                        case 'AZCOSMOSMONGO':
                        case 'AZCOSMOSNOSQL':
                            localComponent.configureData = {
                                isGeoReplication: orderComponent['geo-replication'],
                            };
                            break;
                        case 'AZSQLPAAS':
                            localComponent.configureData = {
                                adSqlAccount: orderComponent['sql-account-name'],
                                IsADSQLAccount: orderComponent['sql-account-name'] ? 'Yes' : 'No',
                                ...(orderComponent['sql-account-name'] === null && {
                                    sqlGrpOwner: {
                                        groupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                                        groupOwnerDetails: {
                                            label: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                                            gpid: data?.activeDirectoryGroupOwner?.id,
                                            name: data?.activeDirectoryGroupOwner?.name,
                                            email: data?.activeDirectoryGroupOwner?.emailId,
                                        },
                                    },
                                }),
                                ...(orderComponent['sql-account-name'] !== null && {
                                    sqlGrpOwner: null,
                                }),
                            };
                            break;
                        default:
                            break;
                    }
                }

                // Add the configured component to the selectedComponents array
                selectedComponents.push(localComponent);
            }
        });
    });

    return selectedComponents;
};
export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null, componentGroups) => {
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const envConfiguration = catalogConfig?.environmentConfigurations?.[0];
    const privilegedADGroup = envConfiguration?.contributorAdGroups?.length ? 'Yes' : 'No';
    const nonPrivilegedADGroup = envConfiguration?.readerAdGroups?.length ? 'Yes' : 'No';
    const resourceMgmtComponents = { components: getSelectedComponentsForEdit(data, componentGroups, envConfiguration?.components) };
    return {
        catalogCode: catalog?.catalogServiceCode,
        // projectId: catalogConfig?.projectId ? catalogConfig?.projectId : null,
        // projectDetails: catalogConfig?.projectId
        //     ? {
        //           businessUnit: catalogConfig?.businessUnit,
        //           hyperionNumber: catalogConfig?.budgetCode,
        //           impactedSectors: catalogConfig?.sector,
        //           projectNumber: catalogConfig?.projectId,
        //       }
        //     : null,
        // businessUnit: catalogConfig?.businessUnit,
        isExternal: catalogConfig?.isExternal ? 'Yes' : 'No',
        appProfile: catalogConfig?.applicationService,
        sector: catalogConfig?.sector,
        // program: catalogConfig?.program === 'Global Shared' ? 'Not Applicable' : catalogConfig?.program,
        // appUrl: envConfiguration?.applicationUrl,
        appContextPath: catalogConfig?.applicationContextPath,
        ...(catalogConfig?.sector?.toLowerCase() === 'cgf' && { division: catalogConfig?.division }),
        ...(['cgf', 'amesa', 'snt dps']?.includes(catalogConfig?.sector?.toLowerCase()) && { region: catalogConfig?.applicationRegion }),
        budgetCode: catalogConfig?.costCenter,
        // billingInfo: catalogConfig?.billingInformation,
        environment: envConfiguration?.environmentDisplayName,
        dataClassification: catalogConfig?.dataClassification,
        // businessAppService: catalogConfig?.businessApplication && catalogConfig?.applicationService ? 'Yes' : 'No',
        // businessApp: catalogConfig?.businessApplication ? catalogConfig?.businessApplication : catalogConfig?.applicationName,
        // businessApp: catalogConfig?.businessApplication,
        appService: !catalogConfig?.applicationService
            ? `${catalogConfig?.applicationName} ${['production']?.includes(catalogConfig?.environment?.toLowerCase()) ? 'PROD' : 'NON-PROD'}`
            : catalogConfig?.applicationService,
        applicationName: catalogConfig?.applicationName,
        appShortName: catalogConfig?.applicationShortName,
        // appOwner: data?.manager?.id,
        appOwner: null,
        appOwnerDetails: { name: data?.manager?.name, gpid: data?.manager?.id },
        businessContact: null,
        businessContactDetails: { gpid: catalogConfig?.businessOwnerId },
        privilegedADGroup,
        nonPrivilegedADGroup,
        ...(privilegedADGroup === 'Yes' && {
            privilegedADGroupList: envConfiguration?.contributorAdGroups.map((app) => ({
                contributorValue: app,
                contributorInputValue: app,
            })),
        }),
        ...(nonPrivilegedADGroup === 'Yes' && {
            nonPrivilegedADGroupList: envConfiguration?.readerAdGroups.map((app) => ({
                readerValue: app,
                readerInputValue: app,
            })),
        }),
        ...(!['production']?.includes(envConfiguration?.environmentDisplayName?.toLowerCase()) &&
            nonPrivilegedADGroup === 'No' && {
                nonPrivilegedADGroupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                nonPrivilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        ...(!['production']?.includes(envConfiguration?.environmentDisplayName?.toLowerCase()) &&
            privilegedADGroup === 'No' && {
                privilegedADGroupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        ...(['production']?.includes(envConfiguration?.environmentDisplayName?.toLowerCase()) &&
            privilegedADGroup === 'No' && {
                privilegedADGroupOwner: `${data?.activeDirectoryGroupOwner?.id} - ${data?.activeDirectoryGroupOwner?.name}`,
                privilegedADGroupOwnerDetails: data?.activeDirectoryGroupOwner,
            }),
        createdBy: data?.created?.by,
        // appSupportMailId: catalogConfig?.appSupportMailId,
        // ...([RICatalogCode003A, RICatalogCode003, RICatalogCode003O, RICatalogCode005]?.includes(catalog?.catalogServiceCode) && {
        //     certificateOwnerOneDetails: { gpid: catalogConfig?.certificateOwnerOne, name: catalogConfig?.certificateOwnerOneName },
        //     certificateOwnerTwoDetails: { gpid: catalogConfig?.certificateOwnerTwo, name: catalogConfig?.certificateOwnerTwoName },
        // }),
        resourceMgmt: {
            [selectedEnv]: resourceMgmtComponents,
        },
        isTouched: false,
    };
};

export const getComponentsFromSchema = ({ componentGroups = [], exceptPrompt = [], particularPrompt = [] }) => {
    // If particularPrompt is specified, filter groups by their groupType
    if (particularPrompt.length > 0) {
        const filteredGroups = componentGroups?.filter((group) => particularPrompt.includes(group.groupType));
        return filteredGroups?.map((group) => ({
            ...group,
            components: group.components.filter((component) => particularPrompt.includes(component.componentId) || particularPrompt.includes(group.groupType)),
        }));
    }

    // Filter groups to exclude specified groupTypes
    const filteredGroups = componentGroups?.filter((group) => !exceptPrompt.includes(group.groupType));

    // Return the filtered groups with their components
    return filteredGroups?.map((group) => ({
        ...group,
        components: group.components.filter((component) => !exceptPrompt.includes(component.componentId)),
    }));
};
// const getTopics = (topics) => {
//     const getConsumerGrps = (grps) => {
//         const res = grps?.map((grp) => grp?.value).toString();
//         return res;
//     };
//     const formedTopics = topics?.map((topic) => ({
//         'topic-name': topic?.topicName || null,
//         'consumer-group': getConsumerGrps(topic?.consumerGroups),
//         'is-transactional': topic?.isTransactionalId === 'Yes',
//         'transaction-prefix-text': topic?.transactionprefixtext || null,
//         'partition-count': topic?.partitions || null,
//         'retention-unit': topic?.retention?.retentionUnit || null,
//         'retention-period': topic?.retention?.retentionDetails?.millisec || null,
//         'producer-id': topic?.writeAccessUser || null,
//         'consumer-id': topic?.readAccessUser || null,
//         'transaction-principal-id': topic?.transactionalPrincipalId || null,
//         // 'is-producer-required': topic?.isWriteAccess === 'Yes',
//         // 'is-consumer-required': topic?.isReadAccess === 'Yes',
//         // 'read-access-groupOwner': topic?.readAccessGroupOwner?.groupOwnerDetails?.gpid || null,
//         // 'write-access-groupOwner': topic?.writeAccessGroupOwner?.groupOwnerDetails?.gpid || null,
//     }));
//     return formedTopics;
// };

export const generateConfigPayload = (components) => {
    return components.map((component) => {
        const result = {
            'component-id': component?.componentId,
        };
        // Add specific configuration data based on the component
        if (component?.configurable && component?.configureData) {
            if (component?.configurationComponentCode === 'AZSERVICEBUS') {
                result.size = component?.configureData?.serviceBusCapacity;
            } else if (component?.configurationComponentCode === ('AZCOSMOSMONGO' || 'AZCOSMOSNOSQL')) {
                result['geo-replication'] = component?.configureData?.isGeoReplication === 'Yes';
            } else if (component?.configurationComponentCode === 'AZSQLPAAS') {
                result['sql-account-name'] = component?.configureData?.adSqlAccount || null;
            } else if (component?.configurationComponentCode === 'APPSERVICE') {
                result['container-os-type'] = component?.configureData?.containerOsType || null;
            } else if (component?.configurationComponentCode === 'CONTAINERAPP') {
                result['container-environment'] = component?.configureData?.containerEnvironment || null;
                result['azure-registry-img-url'] = component?.configureData?.AzContRegistryImgURL || '';
            }
        }

        return result;
    });
};

const getUniqueComponentIdsFromFlatArray = (components = []) => {
    return Array.from(new Set(components?.map((component) => component?.componentId)));
};

const getUniqueComponentIdsFromGroups = (componentGroups) => {
    const allComponentIds = componentGroups?.flatMap((group) => group?.components.map((component) => component.componentId));
    return Array.from(new Set(allComponentIds));
};

// Comparison function
export const compareComponentIdsLength = (components, componentGroups) => {
    const uniqueIdsFromFlatArray = getUniqueComponentIdsFromFlatArray(components);
    const uniqueIdsFromGroups = getUniqueComponentIdsFromGroups(componentGroups);

    const lengthFromFlatArray = uniqueIdsFromFlatArray.length;
    const lengthFromGroups = uniqueIdsFromGroups.length;

    return {
        lengthFromFlatArray,
        lengthFromGroups,
        areEqual: lengthFromFlatArray === lengthFromGroups,
    };
};
