import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import Select from 'pages/reference-implementation/components/select';
import Btn from 'components/button';
import { RIStyles } from 'pages/reference-implementation/Style';
import { isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI002O/utils';

const Aksns = (props) => {
    const { resource, handleDrawerAction, order } = props;
    const [options, setOptions] = React.useState([]);

    const [formData, setFormData] = useState({
        program: resource?.configureData?.program || null,
    });

    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formData.program) {
            newErrors.program = { type: 'required', message: 'Program is required' };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            program: true,
        });
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    const programApi = useMutation((payload) => axios.post(`${endPoints.riServices.COMMON.program}`, payload));

    const resetOptions = () => {
        setOptions([]);
    };

    useEffect(() => {
        if (programApi?.isSuccess) {
            const optionData =
                programApi?.data?.messages?.[0] === 'No Data Found'
                    ? [{ label: 'Not Applicable', value: 'Not Applicable' }]
                    : programApi?.data?.data?.map((app) => ({ label: app, value: app }));
            setOptions(optionData);
        }
    }, [programApi?.isSuccess]);

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    useEffect(() => {
        if (options && options?.length === 1 && !formData?.program) {
            setFormData({ program: options?.[0].value });
        }
    }, [options, formData]);

    React.useEffect(() => {
        if (
            !(
                !order?.sector ||
                !order?.environment ||
                (['cgf']?.includes(order?.sector?.toLowerCase()) && !order?.division) ||
                (isShowAppRegionField({ order }) && !order?.region)
            )
        ) {
            programApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
                ...(isShowAppRegionField({ order }) && { applicationRegion: order?.region }),
            });
        } else {
            resetOptions();
        }
    }, [order?.sector, order?.environment, order?.division, order?.region]);

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Select
                                key="Program"
                                label="Program"
                                value={formData?.program}
                                options={options}
                                handleOnSelect={(value) => handleOnSelectChange('program', value?.value)}
                                isRequired={shouldShowError('program')}
                                isLoading={programApi.isLoading}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

Aksns.propTypes = {
    resource: PropTypes.any.isRequired,
    handleDrawerAction: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
};

export default Aksns;
