import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { componentGroupsState, OrderPayload } from 'pages/reference-implementation/provisioning/RI004O/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { selectedEnv } from '../../utils/constant';
import { getComponentsFromSchema } from '../../utils';

export const ApplicationRegion = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const componentGroups = useRecoilValue(componentGroupsState);
    const [options, setOptions] = React.useState([]);
    const regionApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.regions}?sector=${order?.sector}`));

    const setApplicationRegion = (region) => {
        setOrder({
            ...order,
            region,
            program: null,
            resourceMgmt: {
                [selectedEnv]: {
                    components: getComponentsFromSchema({ componentGroups, particularPrompt: ['mandatoryComponents'] })?.[0]?.components || [],
                },
            },
        });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (region) => options.find((option) => region === option.value);

    const handleOnSelectChange = (selected) => setApplicationRegion(selected.value);

    React.useEffect(() => {
        if (regionApi?.isSuccess) {
            setOptions(regionApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName })));
        }
    }, [regionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.region) {
            setOrder({ ...order, region: options?.[0].value });
        }
        if (options && options?.length && order?.region) {
            const region = isExistsInOptions(order?.region);
            setOrder({ ...order, region: region?.value });
        }
    }, [options]);
    React.useEffect(() => {
        if (order?.sector) {
            resetOptions();
            regionApi.mutate();
        }
    }, [order?.sector]);

    React.useEffect(() => {
        if (!order?.region && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.region, order?.isTouched]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="ApplicationRegion"
                label={<InputLabel label="Application Region" isRequired />}
                value={order?.region || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={regionApi.isLoading}
                isLoading={regionApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(ApplicationRegion);
