// /* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Grid, Box, Alert, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Modal from 'components/modal';
import { customToast } from 'api/utils';
import { OrderPayload, toggleModal, isNonPrivilegedADGroupValid } from 'pages/reference-implementation/provisioning/RI004O/store';
import { addReaderADGroup, removeReaderADGroup } from 'pages/reference-implementation/provisioning/RI004O/store/selector';
import { RI003Styles } from 'pages/reference-implementation/provisioning/RI004O/style';
import Btn from 'components/button';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import ADGroupReader from './ADGroupReader';

const NonPrivilegedADGroupModal = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen, setIsOpen] = useRecoilState(toggleModal);
    const [openInfo, setOpenInfo] = React.useState(true);
    const addGroup = useSetRecoilState(addReaderADGroup);
    const removeGroup = useSetRecoilState(removeReaderADGroup);
    const [lastModifiedNPG, setLastModifiedNPG] = React.useState(undefined);
    const [lastNPValid, setLastNPValid] = React.useState(undefined);
    const [emptyReaderList, setEmptyReaderList] = React.useState([]);
    const [nonPrivilegedADGroupValid, setNonPrivilegedADGroupValid] = useRecoilState(isNonPrivilegedADGroupValid);

    const adGroupValidationApi = useMutation((payload) => axios.post(endPoints.riServices.COMMON.adGroupValidation, payload));
    const isFormValid = () => {
        let errorCount = 0;
        let isReaderADGroupValid = false;
        if (order?.nonPrivilegedADGroupList?.length) {
            order?.nonPrivilegedADGroupList?.forEach((adGroup) => {
                if (adGroup && (adGroup?.readerValue === '' || adGroup?.readerValue === null)) {
                    // eslint-disable-next-line no-plusplus
                    errorCount++;
                }
            });
        }
        isReaderADGroupValid = errorCount === 0;
        return isReaderADGroupValid;
    };
    const isDuplicateADGroup = () => {
        const readerADGroupArray = order?.nonPrivilegedADGroupList?.map((item) => item?.readerValue);
        return new Set(readerADGroupArray).size !== readerADGroupArray.length;
    };

    React.useEffect(() => {
        if (isOpen?.isNonPrivilegedADGroupOpen) {
            setNonPrivilegedADGroupValid(false);
            setLastNPValid(nonPrivilegedADGroupValid);
            setLastModifiedNPG(order?.nonPrivilegedADGroupList);
            setOpenInfo(true);
        }
    }, [isOpen?.isNonPrivilegedADGroupOpen]);

    React.useEffect(() => {
        if (adGroupValidationApi?.isSuccess) {
            if (adGroupValidationApi?.data?.isSuccess) {
                if (!isFormValid() || isDuplicateADGroup()) {
                    customToast(['AD Group cannot be empty or duplicate.']);
                } else {
                    setNonPrivilegedADGroupValid(false);
                    setTimeout(() => {
                        setNonPrivilegedADGroupValid(true);
                    }, 1000);

                    setIsOpen({ ...isOpen, isNonPrivilegedADGroupOpen: false });
                }
            }
        }
    }, [adGroupValidationApi?.isSuccess]);

    React.useEffect(() => {
        if (adGroupValidationApi?.isError && adGroupValidationApi?.error) {
            const errorMessage = Array.isArray(adGroupValidationApi?.error) ? adGroupValidationApi?.error : [adGroupValidationApi?.error?.message];
            if (!isFormValid() || isDuplicateADGroup()) {
                errorMessage.push('AD Group cannot be empty or duplicate.');
            }
            customToast(errorMessage);
        }
    }, [adGroupValidationApi?.isError]);

    const handleSubmit = async () => {
        const emptyArr = [];
        order?.nonPrivilegedADGroupList.forEach((z, i) => {
            if (z?.readerValue === '' || z?.readerValue === null) {
                emptyArr.push(i);
            }
        });
        setEmptyReaderList(emptyArr);
        const adGroups = order?.nonPrivilegedADGroupList?.map((z) => z.readerValue);
        if (adGroups?.length && !adGroups?.some((z) => z === '' || z === null)) {
            const payload = { adGroups, isPrivileged: false };
            adGroupValidationApi?.mutate(payload);
        } else if (!isFormValid() || isDuplicateADGroup()) {
            customToast(['AD Group cannot be empty or duplicate.']);
        }
    };

    const updateEmptyIndexList = (indexVal) => {
        setEmptyReaderList(emptyReaderList?.filter((z) => z !== indexVal));
    };

    return (
        <Modal
            title="Non Privileged Active Directory Groups"
            handleClose={() => {
                setNonPrivilegedADGroupValid(lastNPValid);
                setIsOpen({ ...isOpen, isNonPrivilegedADGroupOpen: false });
                setOrder({
                    ...order,
                    nonPrivilegedADGroupList: lastModifiedNPG,
                });
            }}
            open={isOpen.isNonPrivilegedADGroupOpen}
        >
            <Box sx={RI003Styles.modal.wrapper}>
                <Box sx={{ ...RI003Styles.modal.contentWrapper, paddingTop: '0px' }}>
                    <Grid container spacing={4} sx={{ marginBottom: '10px' }}>
                        <Grid item xs={12} sm={1} />
                        <Grid item xs={12} sm={10}>
                            {openInfo && (
                                <Alert
                                    icon={false}
                                    severity="info"
                                    color="info"
                                    sx={{ paddingTop: '0px', paddingBottom: '0px' }}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenInfo(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    Maximum allowed Active Directory Groups are 10
                                </Alert>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={1} />
                    </Grid>

                    {order?.nonPrivilegedADGroupList?.map((item, index) => (
                        <Box key={index.toString()} sx={RI003Styles.modal.rowWrapper} className="space-x-2 mb-5 animate-opacity">
                            <Box sx={RI003Styles.modal.groupWrapper}>
                                <React.Fragment key={index.toString()}>
                                    <ADGroupReader
                                        indexVal={index}
                                        validationData={adGroupValidationApi?.data?.messages || adGroupValidationApi?.error}
                                        indexList={emptyReaderList}
                                        updateEmptyList={updateEmptyIndexList}
                                    />
                                </React.Fragment>
                            </Box>
                            <Box sx={RI003Styles.modal.iconWrapper}>
                                <Box className="flex w-full ">
                                    {index !== 0 && order?.nonPrivilegedADGroupList?.length > 1 && (
                                        <Box sx={RI003Styles.modal.deleteWrapper}>
                                            <FaTrashAlt
                                                style={RI003Styles.modal.delete}
                                                onClick={() => removeGroup(item)}
                                                data-testid="removeIcon"
                                                className="w-7 h-7 text-red-base cursor-pointer"
                                            />
                                        </Box>
                                    )}
                                    {index === order?.nonPrivilegedADGroupList.length - 1 && order?.nonPrivilegedADGroupList?.length < 10 ? (
                                        <MdAdd
                                            style={RI003Styles.modal.add}
                                            onClick={() => {
                                                addGroup({ count: 1 });
                                            }}
                                            data-testid="addIcon"
                                            className="w-7 h-7 text-blue-base cursor-pointer"
                                        />
                                    ) : (
                                        <span className="w-7 h-7" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="footer" sx={RI003Styles.modal.actionWrapper} gap={2}>
                    <Btn
                        className="custom-btn"
                        variant="contained"
                        color="cmpPrimary2"
                        onClick={() => {
                            handleSubmit();
                        }}
                        data-testid="save-btn"
                    >
                        {adGroupValidationApi?.isLoading ? <CircularProgress color="inherit" size={25} /> : 'Save'}
                    </Btn>
                    <Btn
                        className="custom-btn"
                        sx={RI003Styles.modal.cancelBtn}
                        onClick={() => {
                            setNonPrivilegedADGroupValid(lastNPValid);
                            setIsOpen({ ...isOpen, isNonPrivilegedADGroupOpen: false });
                            setOrder({
                                ...order,
                                nonPrivilegedADGroupList: lastModifiedNPG,
                            });
                        }}
                        variant="outlined"
                        color="cmpWarning"
                        layout="outline"
                        data-testid="cancel-btn"
                    >
                        Cancel
                    </Btn>
                </Box>
            </Box>
        </Modal>
    );
};

export default NonPrivilegedADGroupModal;
