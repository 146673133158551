import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { useRecoilState } from 'recoil';
import { OrderPayload, isAppContextPathValid } from 'pages/reference-implementation/provisioning/RI002O/store';
import { InputInfo } from 'pages/reference-implementation/components/input-info';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const AppContextPath = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setAppContextPathVaild] = useRecoilState(isAppContextPathValid);
    const [helperText, setHelperText] = React.useState(false);
    const setAppContextPath = (appContextPath = null) => {
        setOrder({
            ...order,
            appContextPath,
        });
    };
    React.useEffect(() => {
        if (order?.appContextPath && order?.appContextPath !== '' && !/^\/([a-zA-Z0-9-_]+\/)*[a-zA-Z0-9-_]+$/.test(order?.appContextPath)) {
            setHelperText(true);
            setAppContextPathVaild(false);
        } else if (order?.appContextPath === '') {
            setHelperText(false);
            setAppContextPathVaild(true);
        } else {
            setHelperText(false);
            setAppContextPathVaild(true);
        }
    }, [order?.appContextPath]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <CssTextField
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                label={<InputLabel label="Application Context Path" />}
                error={helperText}
                helperText={helperText && <span>Invalid Context Path.</span>}
                value={order?.appContextPath || ''}
                onChange={(e) => setAppContextPath(e.target.value)}
                className={helperText ? 'validate' : ''}
            />
            <InputInfo title="Application Context Path must start with / and can contain alphanumeric characters, hyphens, or underscores in each segment separated by /." />
        </Box>
    );
};

export default AppContextPath;
