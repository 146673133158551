import React from 'react';
import { AZSERVICEBUS, AZ_DATA_EXP, AZ_DATA_LAKE_STORAGE } from 'pages/reference-implementation/provisioning/RI004O/utils/constant';
import ServiceBus from './ServiceBus';
import AzDataExp from './AzDataExp';
import AzDataLake from './AzDataLake';

const ConfigurationModal = (props) => {
    const { userSelectedResource, handleDrawerAction, order } = props;

    const component = () => {
        const { configurationComponentCode } = userSelectedResource;
        switch (configurationComponentCode) {
            case AZSERVICEBUS: {
                return <ServiceBus resource={userSelectedResource} handleDrawerAction={handleDrawerAction} order={order} />;
            }
            case AZ_DATA_EXP: {
                return <AzDataExp resource={userSelectedResource} handleDrawerAction={handleDrawerAction} order={order} />;
            }
            case AZ_DATA_LAKE_STORAGE: {
                return <AzDataLake resource={userSelectedResource} handleDrawerAction={handleDrawerAction} order={order} />;
            }
            default: {
                return null;
            }
        }
    };

    return component();
};

export default ConfigurationModal;
