import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI002O/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const DataClassification = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);
    const { catalogCode } = useParams();

    const dataClassificationApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.dataClassification?.replace('{RID}', catalogCode)}`));

    const setDataClassification = (dataClassification) => {
        setOrder({ ...order, dataClassification });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (dataClassifiation) => options.find((option) => dataClassifiation === option.value);

    const handleOnSelectChange = (selected) => setDataClassification(selected.value);

    React.useEffect(() => {
        if (dataClassificationApi?.isSuccess) {
            setOptions(dataClassificationApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [dataClassificationApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.dataClassification) {
            setOrder({ ...order, dataClassification: options?.[0].value });
        }
        if (options && options?.length && order?.dataClassification) {
            const dataClassification = isExistsInOptions(order?.dataClassification);
            setOrder({ ...order, dataClassification: dataClassification?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        dataClassificationApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.dataClassification && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.dataClassification, order?.isTouched]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="data-classification-auto"
                key="DataClassification"
                label={<InputLabel label="Data Classification" isRequired />}
                value={order?.dataClassification || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={dataClassificationApi.isLoading}
                isLoading={dataClassificationApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(DataClassification);
