import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Stack } from '@mui/material';
import Select, { CssTextField } from 'pages/reference-implementation/components/select';
import { RIStyles } from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';

const ContainerApp = (props) => {
    const { resource, handleDrawerAction } = props;
    const [formData, setFormData] = useState({
        containerEnvironment: resource?.configureData?.containerEnvironment || '',
        AzContRegistryImgURL: resource?.configureData?.AzContRegistryImgURL || '',
    });
    const environmentApi = useMutation(() => axios.get(endPoints.riServices.COMMON.environments));
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formData.containerEnvironment) {
            newErrors.containerEnvironment = { type: 'required', message: 'Container environment is required' };
        }
        if (!formData.AzContRegistryImgURL) {
            newErrors.AzContRegistryImgURL = { type: 'required', message: 'Azure Container Registry Image URL is required' };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            containerEnvironment: true,
            AzContRegistryImgURL: true,
        });
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    const options = environmentApi?.data?.data?.map((app) => ({ label: app?.displayName, value: app?.displayName, data: app }));
    const value = React.useMemo(
        () => options?.find((item) => item?.value === formData?.containerEnvironment) ?? null,
        [formData?.containerEnvironment, environmentApi]
    );

    useEffect(() => {
        environmentApi.mutate();
    }, []);
    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            key="containerEnvironment"
                            label="Environment *"
                            placeholder="Select Environment"
                            options={options}
                            handleOnSelect={(selectedValue) => handleOnSelectChange('containerEnvironment', selectedValue?.value)}
                            value={value}
                            isLoading={environmentApi.isLoading}
                            isRequired={shouldShowError('containerEnvironment')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
                            <CssTextField
                                size="small"
                                fullWidth
                                autoComplete="off"
                                placeholder="Azure Container Registry Image URL"
                                label="Azure Container Registry Image URL *"
                                value={formData?.AzContRegistryImgURL || ''}
                                onChange={(e) => handleOnSelectChange('AzContRegistryImgURL', e?.target?.value)}
                                className={shouldShowError('AzContRegistryImgURL') ? 'validate' : ''}
                                error={shouldShowError('AzContRegistryImgURL')}
                                isRequired={shouldShowError('AzContRegistryImgURL')}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

ContainerApp.propTypes = {
    resource: PropTypes.any.isRequired,
    handleDrawerAction: PropTypes.func.isRequired,
};

export default ContainerApp;
