export const validateSelection = (selectedComponents, componentGroups) => {
    const errors = {};

    componentGroups.forEach((group) => {
        const selectedCount = selectedComponents[group.groupHeader]?.length || 0;
        const { maxSelection } = group;

        if (maxSelection !== null && selectedCount > maxSelection) {
            errors[group.groupHeader] = `You can only select ${maxSelection} for ${group.groupHeader}.`;
        }
    });

    return errors;
};

export const getSelectedComponentFromCurrentComponent = (components) => {
    return components
        .filter((component) => component.groupType !== 'mandatoryComponents')
        .reduce((acc, component) => {
            const result = { ...acc }; // Create a new object to avoid mutation
            if (!result[component.groupHeader]) {
                result[component.groupHeader] = [];
            }
            if (!result[component.groupHeader].includes(component.componentId)) {
                result[component.groupHeader].push(component.componentId);
            }
            return result;
        }, {});
};

export const findComponentDifferences = (currentComponentList, selectedComponent) => {
    const existingComponents = {};
    const newComponents = {};
    const removedComponents = {};
    // Convert the currentComponentList to a map for quick lookups, ignoring "mandatoryComponents"
    const currentComponentMap = {};
    currentComponentList.forEach((component) => {
        if (component.groupType !== 'mandatoryComponents') {
            currentComponentMap[component.componentId] = component.groupHeader;
        }
    });

    // Track all componentIds in selectedComponent for removal comparison
    const selectedComponentIds = new Set();

    // Check for existing and new components in each category of selectedComponent
    Object.keys(selectedComponent).forEach((category) => {
        existingComponents[category] = [];
        newComponents[category] = [];

        selectedComponent[category].forEach((componentId) => {
            selectedComponentIds.add(componentId);

            if (currentComponentMap[componentId]) {
                existingComponents[category].push(componentId);
            } else {
                newComponents[category].push(componentId);
            }
        });
    });

    // Identify removed components by comparing currentComponentList with selectedComponent
    currentComponentList.forEach((component) => {
        const category = component.groupHeader;

        if (component.groupType !== 'mandatoryComponents' && !selectedComponentIds.has(component.componentId)) {
            if (!removedComponents[category]) {
                removedComponents[category] = [];
            }
            removedComponents[category].push(component.componentId);
        }
    });

    return { existingComponents, newComponents, removedComponents };
};

export const processComponentUpdates = (currentComponents, selectedComponents, componentGroups) => {
    // Find the differences between current and selected components
    const result = findComponentDifferences(currentComponents, selectedComponents);

    // Flatten selectedComponents into a single array of component objects
    const newComponents = Object.keys(selectedComponents)
        .flatMap((groupHeader) => {
            return selectedComponents[groupHeader].map((componentId) => {
                const foundGroup = componentGroups.find((group) => group.components.some((component) => component.componentId === componentId));
                return foundGroup ? foundGroup.components.find((component) => component.componentId === componentId) : null;
            });
        })
        .filter(Boolean);

    // Filter out the components that need to be removed
    const componentsToRemove = new Set(Object.values(result.removedComponents).flat());

    // Create the updated components list
    return currentComponents
        .filter((component) => !componentsToRemove.has(component.componentId)) // Remove components that are in result.removedComponents
        .concat(
            newComponents.filter((newComponent) => {
                // Only add components that are in result.newComponents
                return Object.values(result.newComponents).flat().includes(newComponent.componentId);
            })
        );
};

/**
 * Extracts and returns the first error message from an error object.
 *
 * @param {Object} errors - The object containing error messages.
 * @returns {string|null} - The first error message or null if no errors are present.
 */
export const getFirstErrorMessage = (errors) => {
    const firstErrorKey = Object.keys(errors)[0];
    return firstErrorKey ? errors[firstErrorKey] : null;
};
export const validateComponentLimits = (currentComponents, selectedComponents) => {
    // Flatten selectedComponents into an array of component objects
    const newComponents = Object.keys(selectedComponents).flatMap((groupHeader) => {
        return selectedComponents[groupHeader]
            .map((componentId) => {
                // Find the full component object in currentComponents
                const component = currentComponents.find((c) => c.componentId === componentId && c.groupHeader === groupHeader);
                return component ? { ...component, groupHeader } : null;
            })
            .filter(Boolean); // Filter out any null values if a component wasn't found
    });

    // Combine the existing components with the new ones
    const allComponents = [...currentComponents, ...newComponents];

    // Group components by a unique key based on their properties
    const groupedComponents = allComponents.reduce((acc, component) => {
        const key = `${component.groupType}-${component.groupHeader}-${component.componentId}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(component);
        return acc;
    }, {});

    // Check if any group exceeds the limit
    const exceededComponent = Object.values(groupedComponents).find((components) => components.length > components[0].maxLimitForCloning);

    // Return the result
    if (exceededComponent) {
        const component = exceededComponent[0];
        return {
            exceeded: true,
            component,
            message: `You have already reached the configuration limit for the component "${component.componentName}". You cannot insert more than ${component.maxLimitForCloning} of this component in the "${component.groupHeader}" group.`,
        };
    }

    return {
        exceeded: false,
    };
};

export const validateCloning = (newResource, resourceMgmt) => {
    let errors = {};
    const existingResources = resourceMgmt?.components?.filter(
        (res) => res?.groupType === newResource.groupType && res?.componentId === newResource?.componentId
    );
    const { maxLimitForCloning } = newResource;
    if (existingResources?.length === newResource?.maxLimitForCloning) {
        errors[
            `${newResource?.componentId}-${newResource?.componentName}`
        ] = `You can select a maximum of ${maxLimitForCloning} component(s) for ${newResource?.componentName}.`;
    } else if (existingResources?.length < newResource?.maxLimitForCloning && errors[`${newResource?.componentId}-${newResource?.componentName}`]) {
        const tempError = { ...errors };
        const modifiedErrors = {};
        Object.keys(errors)?.map((key) => {
            if (key !== `${newResource?.componentId}-${newResource?.componentName}`) {
                modifiedErrors[key] = tempError[key];
            }
            return modifiedErrors;
        });
        errors = modifiedErrors;
    }
    return errors;
};
export default {};
