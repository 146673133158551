import React from 'react';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { toggleModal, costEstimateState } from 'pages/reference-implementation/provisioning/RI004O/store';
import { RICatalogCode005 } from 'pages/reference-implementation/utils/RICatalogCode';
import CostDetails from 'pages/reference-implementation/components/cost-details';

export const ShowCostDetails = () => {
    const [costEstimate] = useRecoilState(costEstimateState);
    const [modal, setModal] = useRecoilState(toggleModal);
    const { catalogCode } = useParams();

    return (
        <CostDetails
            type={modal?.isCost?.costType}
            isCostOpen={modal?.isCost?.isCostOpen}
            costEstimate={costEstimate}
            showCostInfo={[RICatalogCode005]?.includes(catalogCode)}
            handleCloseModal={() => setModal({ ...modal, isCost: { costType: '', isCostOpen: false } })}
        />
    );
};

ShowCostDetails.propTypes = {};

ShowCostDetails.defaultProps = {};

export default ShowCostDetails;
