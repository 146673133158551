// import {
//     RICatalogCode004,
//     RICatalogCode004A,
//     RICatalogCode003,
//     RICatalogCode003O,
//     RICatalogCode003A,
//     RICatalogCode005,
// } from 'pages/reference-implementation/utils/RICatalogCode';

// Validates if the order contains the required basic fields
export const isOrderValid = (order) => {
    if (!order) return { isValid: false, message: 'Order data is missing.' };

    // if (!order?.projectId) return { isValid: false, message: 'Project ID is missing.' };
    // if (!order?.businessUnit) return { isValid: false, message: 'Business Unit is missing.' };
    if (!order?.appProfile) return { isValid: false, message: 'Application Service is missing.' };
    if (!order?.sector) return { isValid: false, message: 'Sector is missing.' };
    if (order?.sector?.toLowerCase() === 'cgf' && !order?.division) return { isValid: false, message: 'Division is required for CGF sector.' };
    if (['cgf', 'amesa', 'snt dps'].includes(order?.sector?.toLowerCase()) && !order?.region)
        return { isValid: false, message: 'Region is required for CGF, AMESA, or SNT DPS sector.' };
    if (!order?.environment) return { isValid: false, message: 'Environment is missing.' };
    // if (!order?.program) return { isValid: false, message: 'Program is missing.' };
    if (!order?.appOwner) return { isValid: false, message: 'Application Owner is missing.' };
    if (!order?.budgetCode) return { isValid: false, message: 'Budget Code is missing.' };
    if (!order?.dataClassification) return { isValid: false, message: 'Data Classification is missing.' };
    if (!order?.applicationName) return { isValid: false, message: 'Application Name is missing.' };
    // if (!order?.businessApp) return { isValid: false, message: 'Business Application is missing.' };
    // if (!order?.appService) return { isValid: false, message: 'App Service is missing.' };
    if (!order?.appShortName) return { isValid: false, message: 'App Short Name is missing.' };
    if (!order?.businessContact) return { isValid: false, message: 'Business Contact is missing.' };
    if (!order?.businessContactDetails) return { isValid: false, message: 'Business Contact Details are missing.' };
    // if (order?.isExternal === 'Yes' && [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005] && !order?.certificateOwnerOne) {
    //     return { isValid: false, message: 'Certificate Owner One are missing.' };
    // }
    // if (order?.isExternal === 'Yes' && [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005] && !order?.certificateOwnerTwo) {
    //     return { isValid: false, message: 'Certificate Owner Two are missing.' };
    // }
    return { isValid: true, message: '' };
};

// Validates if the is___Valid flags meet the conditions
export const isValidFlags = ({
    // projectIdValid,
    // businessUnitValid,
    budgetCodeValid,
    appShortNameValid,
    appShortNameExist,
    // appUrlValid,
    appNameValid,
    // businessAppValid,
    // validApplicationSupportMail,
    // validOwners,
    // order,
}) => {
    // if (!projectIdValid) return { isValid: false, message: 'Project ID is invalid.' };
    // if (!businessUnitValid) return { isValid: false, message: 'Business Unit is invalid.' };
    // if (order?.businessAppService === 'No' && !businessAppValid) return { isValid: false, message: 'Business App is invalid.' };
    if (!budgetCodeValid) return { isValid: false, message: 'Budget Code is invalid.' };
    if (!appNameValid) return { isValid: false, message: 'App Name is invalid.' };
    if (!appShortNameValid) return { isValid: false, message: 'App Short Name is invalid.' };
    if (appShortNameExist) return { isValid: false, message: 'App Short Name already exists.' };
    // if (order?.isExternal === 'Yes' && ![RICatalogCode004, RICatalogCode004A]?.includes(order?.catalogCode) && (!order?.appUrl || !appUrlValid)) {
    //     return { isValid: false, message: 'App URL is required and must be valid for the given catalog code.' };
    // }
    // if (
    //     order?.isExternal === 'Yes' &&
    //     [RICatalogCode003, RICatalogCode003O, RICatalogCode003A]?.includes(order?.catalogCode) &&
    //     (!order?.appSupportMailId || validApplicationSupportMail)
    // ) {
    //     return { isValid: false, message: 'App Support Mail ID is required and must be valid for the given catalog code.' };
    // }
    // if (
    //     order?.isExternal === 'Yes' &&
    //     [RICatalogCode003, RICatalogCode003O, RICatalogCode003A, RICatalogCode005]?.includes(order?.catalogCode) &&
    //     !Object.values(validOwners).every(Boolean)
    // ) {
    //     return { isValid: false, message: 'One or more owners are invalid for the given catalog code.' };
    // }

    return { isValid: true, message: '' };
};

// Validates if the privileged AD group is valid
export const validPrivilegedADGroup = (order, privilegedADGroupValid) => {
    if (order?.privilegedADGroup === 'Yes' && (!privilegedADGroupValid || order?.privilegedADGroupList?.some((z) => !z?.contributorValue))) {
        return { isValid: false, message: 'Privileged AD Group is invalid.' };
    }
    return { isValid: true, message: '' };
};

// Validates if the non-privileged AD group is valid
export const validNonPrivilegedADGroup = (order, nonPrivilegedADGroupValid) => {
    if (order?.nonPrivilegedADGroup === 'Yes' && (!nonPrivilegedADGroupValid || order?.nonPrivilegedADGroupList?.some((z) => !z?.readerValue))) {
        return { isValid: false, message: 'Non-Privileged AD Group is invalid.' };
    }
    return { isValid: true, message: '' };
};

// Validates if there are any invalid groups based on environment
export const hasInvalidGroups = (order, privilegedADGroupValid, nonPrivilegedADGroupValid) => {
    const isProduction = order?.environment?.toLowerCase() === 'production';
    const isNonProduction = !isProduction;

    if (!validPrivilegedADGroup(order, privilegedADGroupValid).isValid) return validPrivilegedADGroup(order, privilegedADGroupValid);
    if (isNonProduction) {
        if (!validNonPrivilegedADGroup(order, nonPrivilegedADGroupValid).isValid) return validNonPrivilegedADGroup(order, nonPrivilegedADGroupValid);
    }

    if (isNonProduction && !order?.nonPrivilegedADGroup && !order?.privilegedADGroup)
        return { isValid: false, message: 'Both Privileged and Non-Privileged AD Groups are missing for non-production environment.' };
    if (isNonProduction && !order?.nonPrivilegedADGroup && order?.privilegedADGroup)
        return { isValid: false, message: 'Non-Privileged AD Groups are missing for non-production environment.' };
    if (isNonProduction && order?.nonPrivilegedADGroup && !order?.privilegedADGroup)
        return { isValid: false, message: 'Privileged AD Groups are missing for non-production environment.' };

    if (isProduction && !order?.privilegedADGroup) return { isValid: false, message: 'Privileged AD Group is missing for production environment.' };

    if (isNonProduction && order?.nonPrivilegedADGroup === 'No' && (!order?.nonPrivilegedADGroupOwner || !order?.nonPrivilegedADGroupOwnerDetails))
        return { isValid: false, message: 'Non-Privileged AD Group owner details are missing for non-production environment.' };

    if (isNonProduction && order?.privilegedADGroup === 'No' && (!order?.privilegedADGroupOwner || !order?.privilegedADGroupOwnerDetails))
        return { isValid: false, message: 'Privileged AD Group owner details are missing for non-production environment.' };

    if (isProduction && order?.privilegedADGroup === 'No' && (!order?.privilegedADGroupOwner || !order?.privilegedADGroupOwnerDetails))
        return { isValid: false, message: 'Privileged AD Group owner details are missing for production environment.' };

    return { isValid: true, message: '' };
};

export const validateConfigurationData = (components = []) => {
    return components?.map((component) => {
        const { componentId, configurable, configureData } = component;
        if (configurable) {
            const isValid = !!configureData && Object.keys(configureData).length > 0;
            return {
                isValid,
                message: isValid ? 'Configuration is valid.' : `Configuration for ${componentId} is missing or invalid.`,
            };
        }
        return {
            isValid: true,
            message: `Component ${componentId} is not configurable.`,
        };
    });
};
