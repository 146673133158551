import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import { FiAlertTriangle } from 'react-icons/fi';
import Btn from 'components/button';
import { RI003Styles } from 'pages/reference-implementation/provisioning/RI002O/style';

export default function ConfirmDialog({ title, primaryBtnText, secondaryBtnText, primaryBtnAction, secondaryBtnAction, content, isModalOpen }) {
    return (
        <Dialog
            open={isModalOpen}
            onClose={secondaryBtnAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-testid="confirmDialog"
        >
            <DialogContent>
                <Box sx={RI003Styles.confirmDialog.dialogContentBox}>
                    <FiAlertTriangle color={RI003Styles.confirmDialog.IconColor} size={70} />
                    <Typography variant="h5">{title}</Typography>
                    {content ? <div className="text-gray-dark">{content}</div> : null}
                </Box>

                <Box sx={RI003Styles.confirmDialog.dialogActionBox}>
                    <Btn
                        variant="contained"
                        onClick={() => primaryBtnAction()}
                        className="uppercase tracking-wider text-sm custom-btn"
                        color="cmpPrimary"
                        sx={{ textTransform: 'none' }}
                        data-testid="yes-btn"
                    >
                        {primaryBtnText}
                    </Btn>
                    <Btn
                        variant="outlined"
                        color="cmpWarning"
                        sx={RI003Styles.confirmDialog.dialogNoBtn}
                        onClick={() => secondaryBtnAction()}
                        layout="outline"
                        className="uppercase tracking-wider text-sm custom-btn"
                        data-testid="no-btn"
                    >
                        {secondaryBtnText}
                    </Btn>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    primaryBtnText: PropTypes.string.isRequired,
    secondaryBtnText: PropTypes.string.isRequired,
    primaryBtnAction: PropTypes.func.isRequired,
    secondaryBtnAction: PropTypes.func.isRequired,
    content: PropTypes.any,
    isModalOpen: PropTypes.bool.isRequired,
};

ConfirmDialog.defaultProps = {
    title: '',
    content: null,
};
