import React from 'react';
import { Box, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI002O/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { checkIsApplicable, isShowAppRegionField } from 'pages/reference-implementation/provisioning/RI002O/utils';

export const Program = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const programApi = useMutation((payload) => axios.post(`${endPoints.riServices.COMMON.program}`, payload));

    const setProgram = (program) => {
        setOrder({ ...order, program });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (program) => options.find((option) => program === option.value);
    const handleOnSelectChange = (selected) => setProgram(selected.value);
    const isShow = () => checkIsApplicable(options) || programApi?.data?.messages?.[0] === 'No Data Found';
    React.useEffect(() => {
        if (programApi?.isSuccess) {
            setOptions(programApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [programApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.program) {
            setOrder({ ...order, program: options?.[0].value });
        }
        if (options && options?.length > 1 && order?.program) {
            const program = isExistsInOptions(order?.program);
            setOrder({ ...order, program: program?.value });
        }
    }, [options]);

    React.useEffect(() => {
        if (
            !(
                !order?.sector ||
                !order?.environment ||
                (['cgf']?.includes(order?.sector?.toLowerCase()) && !order?.division) ||
                (isShowAppRegionField({ order }) && !order?.region)
            )
        ) {
            programApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                ...(['cgf']?.includes(order?.sector?.toLowerCase()) && { division: order?.division }),
                ...(isShowAppRegionField({ order }) && { applicationRegion: order?.region }),
            });
        } else {
            resetOptions();
        }
    }, [order?.sector, order?.environment, order?.division, order?.region]);

    React.useEffect(() => {
        if (!order?.program && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.program, order?.isTouched]);
    return (
        <>
            {programApi?.isSuccess && isShow() && (
                <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Select
                            key="Program"
                            label={<InputLabel label="Program" isRequired />}
                            value={order?.program || null}
                            options={options}
                            handleOnSelect={(value) => handleOnSelectChange(value)}
                            disabled={programApi.isLoading || !order?.sector || !order?.environment}
                            isLoading={programApi.isLoading}
                            isRequired={isTouched}
                        />
                    </Box>
                </Grid>
            )}
        </>
    );
};

export default React.memo(Program);
