import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import { RIStyles } from 'pages/reference-implementation/Style';
import useDebounce from 'pages/reference-implementation/provisioning/RI004O/hooks/use-debounce';
import { CssTextField } from 'pages/reference-implementation/components/select';
import Btn from 'components/button';
import { useMutation } from 'react-query';
import axios from 'api/request';
import { endPoints } from 'api/endpoints';

const AzDataExp = (props) => {
    const { resource, handleDrawerAction, order } = props;
    const [formData, setFormData] = useState({
        clusterName: resource?.configureData?.clusterName || '',
        cosmosDbAcName: resource?.configureData?.cosmosDbAcName || '',
        cosmosDbName: resource?.configureData?.cosmosDbName || '',
    });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateCosmosDBAccApi = useMutation((payload) => axios.post(endPoints.riServices.RIIOTEVENTHUB.validateCosmosDBAccName, payload));
    const validateClusterNameApi = useMutation((payload) => axios.post(endPoints.riServices.RIIOTEVENTHUB.validateClusterName, payload));

    const debounceCosmosDB = useDebounce(formData?.cosmosDbAcName, 500);
    const debounceCluster = useDebounce(formData?.clusterName, 500);

    const validateForm = () => {
        const newErrors = {};

        // Validate
        if (!formData.clusterName) {
            newErrors.clusterName = { type: 'required', message: 'Cluster Name is required' };
        }
        if (!formData.cosmosDbAcName) {
            newErrors.cosmosDbAcName = { type: 'required', message: 'Cosmos Database Account Name is required' };
        }
        if (!formData.cosmosDbName) {
            newErrors.cosmosDbName = { type: 'required', message: 'Cosmos DB Name is required' };
        }
        // API error
        if (formData.cosmosDbAcName && errors?.cosmosDbAcName) {
            newErrors.cosmosDbAcName = errors?.cosmosDbAcName;
        }
        if (formData.clusterName && errors?.clusterName) {
            newErrors.clusterName = errors?.clusterName;
        }
        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            clusterName: true,
            cosmosDbAcName: true,
            cosmosDbName: true,
        });
        const checkFormValid = validateForm();
        if (!validateCosmosDBAccApi?.isLoading && !validateClusterNameApi?.isLoading) handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    useEffect(() => {
        if (validateCosmosDBAccApi?.data?.isSuccess === true) {
            setErrors({ ...errors, cosmosDbAcName: '' });
        } else if (validateCosmosDBAccApi?.data?.isSuccess === false) {
            setErrors({ ...errors, cosmosDbAcName: { type: 'required', message: validateCosmosDBAccApi?.data?.messages?.[0] || '' } });
        }
    }, [validateCosmosDBAccApi?.isSuccess]);

    useEffect(() => {
        if (validateClusterNameApi?.data?.isSuccess === true) {
            setErrors({ ...errors, clusterName: '' });
        } else if (validateClusterNameApi?.data?.isSuccess === false) {
            setErrors({ ...errors, clusterName: { type: 'required', message: validateClusterNameApi?.data?.messages?.[0] || '' } });
        }
    }, [validateClusterNameApi?.isSuccess]);

    useEffect(() => {
        if (debounceCosmosDB && debounceCosmosDB !== null && debounceCosmosDB !== '' && debounceCosmosDB !== undefined) {
            validateCosmosDBAccApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                catalogServiceCode: 'RI004',
                name: debounceCosmosDB,
                division: null,
                applicationRegion: null,
            });
        }
    }, [debounceCosmosDB]);

    useEffect(() => {
        if (debounceCluster && debounceCluster !== null && debounceCluster !== '' && debounceCluster !== undefined) {
            validateClusterNameApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                catalogServiceCode: 'RI004',
                name: debounceCluster,
                division: null,
                applicationRegion: null,
            });
        }
    }, [debounceCluster]);

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
                            <CssTextField
                                size="small"
                                fullWidth
                                autoComplete="off"
                                placeholder="Cluster Name"
                                label="Cluster Name *"
                                value={formData?.clusterName || ''}
                                onChange={(e) => handleOnSelectChange('clusterName', e?.target?.value)}
                                className={shouldShowError('clusterName') ? 'validate' : ''}
                                error={shouldShowError('clusterName')}
                                helperText={shouldShowError('clusterName') && errors.clusterName.type === 'required' ? errors.clusterName.message : ''}
                                InputProps={{
                                    endAdornment: <>{validateClusterNameApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
                            <CssTextField
                                size="small"
                                fullWidth
                                autoComplete="off"
                                placeholder="Cosmos Database Account Name"
                                label="Cosmos Database Account Name *"
                                value={formData?.cosmosDbAcName || ''}
                                onChange={(e) => handleOnSelectChange('cosmosDbAcName', e?.target?.value)}
                                className={shouldShowError('cosmosDbAcName') ? 'validate' : ''}
                                error={shouldShowError('cosmosDbAcName')}
                                helperText={shouldShowError('cosmosDbAcName') && errors.cosmosDbAcName.type === 'required' ? errors.cosmosDbAcName.message : ''}
                                InputProps={{
                                    endAdornment: <>{validateCosmosDBAccApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} sx={RIStyles.stack}>
                            <CssTextField
                                size="small"
                                fullWidth
                                autoComplete="off"
                                placeholder="Cosmos DB Name"
                                label="Cosmos DB Name *"
                                value={formData?.cosmosDbName || ''}
                                onChange={(e) => handleOnSelectChange('cosmosDbName', e?.target?.value)}
                                className={shouldShowError('cosmosDbName') ? 'validate' : ''}
                                error={shouldShowError('cosmosDbName')}
                                helperText={shouldShowError('cosmosDbName') && errors.cosmosDbName.type === 'required' ? errors.cosmosDbName.message : ''}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

AzDataExp.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func,
    order: PropTypes.any.isRequired,
};

AzDataExp.defaultProps = {
    resource: null,
    handleDrawerAction: PropTypes.func,
};

export default AzDataExp;
