import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { RIStyles } from 'pages/reference-implementation/Style';
import Select from 'pages/reference-implementation/components/select';
import Btn from 'components/button';

const osTypes = [
    { label: 'Windows', value: 'Windows' },
    { label: 'Linux', value: 'Linux' },
];

const AppService = (props) => {
    const { resource, handleDrawerAction } = props;
    const [formData, setFormData] = useState({
        containerOsType: resource?.configureData?.containerOsType || '',
    });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Validate containerOsType
        if (!formData.containerOsType) {
            newErrors.containerOsType = { type: 'required', message: 'Container OS Type is required' };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            containerOsType: true,
        });
        const checkFormValid = validateForm();
        handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    const value = React.useMemo(() => osTypes.find((item) => item?.value === formData?.containerOsType) ?? null, [formData?.containerOsType]);

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Select
                            key="container-os-type"
                            label="Container OS Type *"
                            placeholder="Select Container OS Type"
                            options={osTypes}
                            handleOnSelect={(selectedOption) => handleOnSelectChange('containerOsType', selectedOption?.value)}
                            value={value}
                            isRequired={shouldShowError('containerOsType')}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

AppService.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func,
};

AppService.defaultProps = {
    resource: null,
    handleDrawerAction: PropTypes.func,
};

export default AppService;
