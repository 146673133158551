import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Card, Grid } from '@mui/material';
import {
    oracleIaaSDefinitionId,
    pECatalogDefinitionId,
    NsgAutomationRulesProvisioningCatalogDefinitionID,
    ProjectOnboardCatalogDefinitionId,
} from 'utils/catalogDefinitionId';
import VSTypeDetails from 'pages/provisioning/virtual-service/components/preview/VSTypeDetails';
import approvalStyle from 'components/order-summary-details/style';
import orderStyle from 'pages/orders/style';
import LabelValue from 'components/label-value';
import CostSplit from 'components/order-summary-details/cost-split';
import MutipleResource from 'pages/provisioning/private-endpoint-v2/components/Preview/MutipleResource';
import MultipleResourceNsg from 'pages/provisioning/nsg-automation-rules/components/Preview/MultipleResourceNsg';
import MembersRequested from './MembersRequested';
import ProjectOnboardingMembersDetails from '../project-onboarding-members-details';

const GetSummary = ({ catalog, selectedApprovalId }) => (
    <>
        {Object.keys(catalog?.summary).map((label) => (
            <React.Fragment key={label}>
                {label === 'cost' && (
                    <CostSplit key={label} data={catalog?.summary[label]} width={oracleIaaSDefinitionId?.includes(catalog?.catalogServiceCode) ? 228 : 178} />
                )}
                {!['cost', 'members', 'uploadScan', 'Fortify / Snyk - Code Repository', 'codeRepository', 'uploadArchitecture', 'virtualService'].includes(
                    label
                ) && (
                    <Grid className="" key={label} xs={12} sx={approvalStyle.gridLabel} item>
                        <LabelValue
                            width={oracleIaaSDefinitionId?.includes(catalog?.catalogServiceCode) ? 228 : 178}
                            key={label}
                            label={`${label}`}
                            value={catalog?.summary[label]}
                        />
                    </Grid>
                )}
                {label === 'uploadScan' && (
                    <Grid className="" key={label} xs={12} sx={approvalStyle.gridLabel} item>
                        <Box display="flex" className="key" flexWrap="wrap" p={1}>
                            <Box className="label" width="41%">
                                Uploaded Scan Evidence:
                            </Box>
                            <Box className="value" sx={{ wordBreak: 'break-all', width: '59%' }}>
                                {catalog?.summary?.uploadScan?.map((row, id) => (
                                    <li key={id} style={{ listStyle: 'none', marginTop: '5px' }}>
                                        <b>{id + 1})</b> {row?.fileName}
                                    </li>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                )}
                {label === 'uploadArchitecture' && (
                    <Grid className="" key={label} xs={12} sx={approvalStyle.gridLabel} item>
                        <Box display="flex" className="uploadArchitecture" flexWrap="wrap" p={1}>
                            <Box className="label" width="41%">
                                Uploaded Architecture Document:
                            </Box>
                            <Box className="value" sx={{ wordBreak: 'break-all', width: '59%' }}>
                                {catalog?.summary?.uploadArchitecture?.map((row, id) => (
                                    <li key={id} style={{ listStyle: 'none', marginTop: '5px' }}>
                                        <b>{id + 1})</b> {row?.fileName}
                                    </li>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                )}
                {label === 'members' && !ProjectOnboardCatalogDefinitionId?.includes(catalog?.catalogServiceCode) && (
                    <Box flex="100%">
                        <Card sx={orderStyle?.orderRIProvisionDetails?.summaryMembersCard?.membersCard}>
                            <Typography component="span" sx={orderStyle?.orderRIProvisionDetails?.summaryMembersCard?.memberType}>
                                Members:
                            </Typography>
                            <MembersRequested membersDetails={catalog?.summary?.members} />
                        </Card>
                    </Box>
                )}
                {label === 'members' && ProjectOnboardCatalogDefinitionId?.includes(catalog?.catalogServiceCode) && (
                    <Box flex="100%">
                        <Card sx={orderStyle?.orderRIProvisionDetails?.summaryMembersCard?.projectOnboardingMembersCard}>
                            <Typography component="span" sx={orderStyle?.orderRIProvisionDetails?.summaryMembersCard?.memberType}>
                                Members:
                            </Typography>
                            <ProjectOnboardingMembersDetails membersDetails={catalog?.summary?.members} />
                        </Card>
                    </Box>
                )}

                {label === 'codeRepository' && (
                    <Grid className="" key={label} xs={12} sx={approvalStyle.gridLabel} item>
                        <Box display="flex" className="key" flexWrap="wrap" p={1}>
                            <Box className="label" width="41%">
                                Code Repository:
                            </Box>
                            <Box className="value" sx={{ wordBreak: 'break-all', width: '59%' }}>
                                {catalog?.summary?.codeRepository?.map((row, id) => (
                                    <li key={id} style={{ listStyle: 'none', marginTop: '5px' }}>
                                        <b>{id + 1})</b> {row}
                                    </li>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                )}
                {label === 'Fortify / Snyk - Code Repository' && (
                    <Grid className="" key={label} xs={12} sx={approvalStyle.gridLabel} item>
                        <Box display="flex" className="key" flexWrap="wrap" p={1}>
                            <Box className="label" width="41%">
                                Fortify / Snyk - Code Repository:
                            </Box>
                            <Box className="value" sx={{ wordBreak: 'break-all', width: '59%' }}>
                                {catalog?.summary?.['Fortify / Snyk - Code Repository']?.map((row, id) => (
                                    <li key={id} style={{ listStyle: 'none', marginTop: '5px' }}>
                                        <b>{id + 1})</b> {row}
                                    </li>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                )}
                {label === 'virtualService' && (
                    <Grid className="" key={label} xs={12} sx={approvalStyle.gridLabel} item>
                        {Object.keys(catalog?.summary[label])?.map((vsType) => (
                            <VSTypeDetails virtualServiceData={catalog?.summary[label]} vsType={vsType} />
                        ))}
                    </Grid>
                )}
            </React.Fragment>
        ))}
        {pECatalogDefinitionId.includes(catalog?.catalogServiceCode) && (
            <Grid className="" key="peMultiRes" xs={12} sx={approvalStyle.gridLabel} item>
                <>
                    <MutipleResource resources={catalog?.resources} defaultExpanded={false} isLastPanelOpen={false} isOrderPage />
                </>
            </Grid>
        )}
        {NsgAutomationRulesProvisioningCatalogDefinitionID.includes(catalog?.catalogServiceCode) && (
            <Grid className="" key="nsgMultiRes" xs={12} sx={approvalStyle.gridLabel} item>
                <>
                    <MultipleResourceNsg resources={catalog?.resources} defaultExpanded={false} isLastPanelOpen={false} isOrderPage />
                </>
            </Grid>
        )}
        {Object.keys(catalog?.summary)?.length % 2 !== 0 && (
            <>
                <Grid className="" xs={12} item key={selectedApprovalId}>
                    &nbsp;
                </Grid>
            </>
        )}
    </>
);

GetSummary.propTypes = {
    catalog: PropTypes.any,
    selectedApprovalId: PropTypes.any,
};

GetSummary.defaultProps = {
    catalog: undefined,
    selectedApprovalId: '',
};

export default React.memo(GetSummary);
