export const RICatalogCode001 = 'RI001';
export const RICatalogCode002 = 'RI002';
export const RICatalogCode003 = 'RI003';
export const RICatalogCode004 = 'RI004';
export const RICatalogCode005 = 'RI005';
export const RICatalogCode003A = 'RI003A';
export const RICatalogCode004A = 'RI004A';
export const RICatalogCode001A = 'RI001A';
export const RICatalogCode003O = 'RI003O';
export const RICatalogCode002O = 'RICONSUMERAPP';
export const RICatalogCode004O = 'RIIOTEVENTHUB';
