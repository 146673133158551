import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { useMutation } from 'react-query';
import Select from 'pages/reference-implementation/components/select';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI004O/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';

export const SiteName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const siteNameApi = useMutation(() => axios.get(`${endPoints.riServices.RIIOTEVENTHUB.Jwt.siteName}`));

    const setDataClassification = (siteName) => {
        setOrder({ ...order, siteName });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (siteName) => options.find((option) => siteName === option.value);

    const handleOnSelectChange = (selected) => setDataClassification(selected.value);

    React.useEffect(() => {
        if (siteNameApi?.isSuccess) {
            setOptions(siteNameApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [siteNameApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.siteName) {
            setOrder({ ...order, siteName: options?.[0].value });
        }
        if (options && options?.length && order?.siteName) {
            const siteName = isExistsInOptions(order?.siteName);
            setOrder({ ...order, siteName: siteName?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        siteNameApi.mutate();
    }, []);

    React.useEffect(() => {
        if (!order?.siteName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.siteName, order?.isTouched]);
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                dataTestId="site-name-auto"
                key="SiteName"
                label={<InputLabel label="Site Name" isRequired />}
                value={order?.siteName || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={siteNameApi.isLoading}
                isLoading={siteNameApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(SiteName);
