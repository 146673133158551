export const formOrderObjFromCart = (data = null) => {
    const entityDetails = data?.itemDetail?.entityDetails || null;
    const environmentDetails = data?.itemDetail?.catalogService?.catalogConfig?.environmentDetails || null;
    const deploymentModel = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPaasHost?.purchaseModel || null;
    const deploymentType = data?.itemDetail?.catalogService?.catalogConfig?.deploymentType || null;
    const sku = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase?.sku || null;
    const azureSqlPaasHost = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPaasHost || null;
    const elastic = data?.itemDetail?.catalogService?.catalogConfig?.elasticPool || null;
    // const azureSqlPurchase = data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: entityDetails?.costCenter,
            projectName: entityDetails?.project,
        },
        tagsQc: { ...data?.itemDetail?.catalogService?.catalogConfig?.tags },
        sector: data?.itemDetail?.catalogService?.sector || null,
        billingEntity: data?.itemDetail?.catalogService?.catalogConfig?.billingEntity || null,
        costCenter: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            costCenterDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!entityDetails?.costCenter && { costCenterDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        serverCollation: data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase?.collation || null,
        logicalHostExist: azureSqlPaasHost?.isLogicalHostExists ? 'Yes' : 'No',

        zoneRedundancy: data?.itemDetail?.catalogService?.catalogConfig?.zoneRedundancy === true ? 'yes' : 'no',
        logicalHost: azureSqlPaasHost?.name || null,
        sqlDatabaseName: azureSqlPaasHost?.dbName || null,
        pregeoReplication: data?.itemDetail?.catalogService?.catalogConfig?.disasterRecovery || data?.itemDetail?.catalogService?.catalogConfig?.geoReplication,
        secondarylogicalHost: data?.itemDetail?.catalogService?.catalogConfig?.secondaryServerName,
        failOverGrpName: data?.itemDetail?.catalogService?.catalogConfig?.failOverGroupName,
        ...(data?.itemDetail?.catalogService?.catalogConfig?.secondaryServerName &&
            data?.itemDetail?.catalogService?.catalogConfig?.failOverGroupName && {
                geoSecondaryReplication: 'yes',
            }),
        applicationName: data?.itemDetail?.catalogService?.catalogConfig?.applicationName || null,
        appShortName: data?.itemDetail?.catalogService?.catalogConfig?.applicationShortName || null,
        sqlServiceAccountName: data?.itemDetail?.catalogService?.catalogConfig?.sqlAccountName || null,
        ...(data?.itemDetail?.catalogService?.catalogConfig?.sqlAccountName && {
            isSqlAccExist: 'yes',
        }),
        ...(data?.itemDetail?.catalogService?.catalogConfig?.applicationName &&
            data?.itemDetail?.catalogService?.catalogConfig?.applicationShortName && {
                isSqlAccExist: 'no',
            }),
        sqlAccOwnerDetails: { gpid: data?.itemDetail?.catalogService?.activeDirectoryGroupOwnerId, name: null },
        sqlDbAuthentication: data?.itemDetail?.catalogService?.catalogConfig?.sqlDatabaseAuthentication ? 'yes' : 'no',
        deploymentType: deploymentType === 'Elastic Pool' ? 'Azure SQL Elastic Pool' : deploymentType || null,
        ...(deploymentType === 'Elastic Pool' && { elasticPoolExist: elastic?.isExistingPool ? 'Yes' : 'No', elasticPoolName: elastic?.name || '' }),
        // eslint-disable-next-line
        deploymentModel: deploymentModel === 'DTU' ? 'DTU Based' : deploymentModel === 'vCore' ? 'VCore Based' : null,
        deploymentModelDetails: null,
        serviceTiers: deploymentModel === 'DTU' ? `${sku?.name}-${sku?.capacity} ${deploymentModel}` : sku?.tier || null,
        ...(deploymentModel === 'vCore' && { vCoresCount: sku?.capacity }), // vCore
        storageSize: data?.itemDetail?.catalogService?.catalogConfig?.azureSqlPurchase?.maxSizeBytes || null,
        catalogCode: data?.itemDetail?.catalogService?.catalogServiceCode,
        catalogType: data?.itemDetail?.catalogService?.catalogType,
        cloudProvider: data?.itemDetail?.catalogService?.cloudProvider,
        cloudService: data?.itemDetail?.catalogService?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const formOrderObjFromOrderDetails = (data = null, catalogServiceId = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    const deploymentModel = catalogConfig?.azureSqlPaasHost?.purchaseModel || null;
    const deploymentType = catalogConfig?.deploymentType || null;
    const sku = catalogConfig?.azureSqlPurchase?.sku || null;
    const azureSqlPaasHost = catalogConfig?.azureSqlPaasHost || null;
    const elastic = catalogConfig?.elasticPool || null;

    return {
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: environmentDetails?.costCenter,
            projectName: entityDetails?.project,
            billingEntity: catalogConfig?.billingEntity,
            sector: catalog?.sector,
        },
        tagsQc: { ...catalogConfig?.tags },
        sector: catalog?.sector || null,
        billingEntity: catalogConfig?.billingEntity || null,
        costCenter: environmentDetails?.costCenter || null,
        ...(environmentDetails?.costCenter && {
            costCenterDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { costCenterDetails: null }),
        environment: environmentDetails?.environment || null,
        appTier: environmentDetails?.applicationTier || null,
        appTierCode: environmentDetails?.appTierCode || null,
        region: environmentDetails?.region || null,
        landingZoneDetails: {
            id: environmentDetails?.landingZoneId,
        },
        // regionDisplayName: data?.region?.name || null,
        serverCollation: catalogConfig?.azureSqlPurchase?.collation || null,
        logicalHostExist: azureSqlPaasHost?.isLogicalHostExists ? 'Yes' : 'No',
        zoneRedundancy: catalogConfig?.zoneRedundancy === true ? 'yes' : 'no',
        logicalHost: azureSqlPaasHost?.name || null,
        sqlDatabaseName: azureSqlPaasHost?.dbName || null,
        pregeoReplication: catalogConfig?.disasterRecovery || catalogConfig?.geoReplication,
        secondarylogicalHost: catalogConfig?.secondaryServerName,
        failOverGrpName: catalogConfig?.failOverGroupName,
        ...(catalogConfig?.secondaryServerName &&
            catalogConfig?.failOverGroupName && {
                geoSecondaryReplication: 'yes',
            }),
        applicationName: catalogConfig?.applicationName || null,
        appShortName: catalogConfig?.applicationShortName || null,
        sqlServiceAccountName: catalogConfig?.sqlAccountName || null,
        ...(catalogConfig?.sqlAccountName && { isSqlAccExist: 'yes' }),
        ...(catalogConfig?.applicationName && catalogConfig?.applicationShortName && { isSqlAccExist: 'no' }),

        sqlAccOwnerDetails: { gpid: catalog?.activeDirectoryGroupOwnerId, name: null },
        sqlDbAuthentication: catalogConfig?.sqlDatabaseAuthentication ? 'yes' : 'no',
        deploymentType: deploymentType === 'Elastic Pool' ? 'Azure SQL Elastic Pool' : deploymentType || null,
        ...(deploymentType === 'Elastic Pool' && { elasticPoolExist: elastic?.isExistingPool ? 'Yes' : 'No', elasticPoolName: elastic?.name || '' }),
        // eslint-disable-next-line
        deploymentModel: deploymentModel === 'DTU' ? 'DTU Based' : deploymentModel === 'vCore' ? 'VCore Based' : null,
        deploymentModelDetails: null,
        serviceTiers: deploymentModel === 'DTU' ? `${sku?.name}-${sku?.capacity} ${deploymentModel}` : sku?.tier || null,
        ...(deploymentModel === 'vCore' && { vCoresCount: sku?.capacity }), // vCore
        storageSize: catalogConfig?.azureSqlPurchase?.maxSizeBytes || null,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};
export const formOrderObjFromEiapObject = (data = null, cloudService = null) => {
    const currentComponent = data?.landingZoneDetails?.config?.components?.filter((component) => component?.service === cloudService);
    return {
        appProfile: data?.appProfile,
        appProfileDetails: data?.appProfileDetails,
        entityDetails: data?.entityDetails,
        billingEntity: data?.entityDetails?.billingEntity || null,
        costCenter: data?.entityDetails?.financialDetails || null,
        ...(data?.entityDetails?.financialDetails && {
            costCenterDetails: { label: data?.entityDetails?.financialDetails, projectName: data?.entityDetails?.projectName },
        }),
        ...(!data?.entityDetails?.financialDetails && { costCenterDetails: null }),
        sector: data?.sector,
        environment: data?.environment,
        region: data?.region || null,
        landingZoneDetails: {
            id: currentComponent?.[0]?.landingzoneId || null,
        },
    };
};
export const resetCostEstimateDetails = {
    details: [],
    qty: null,
    totalOneTimeCost: {
        frequency: 'one time',
        estCost: null,
        currency: 'USD',
    },
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: null,
        currency: 'USD',
    },
    notes: 'Total Est. Cost does not include the DR services cost & any Pepsico internal chargebacks',
};

export const resetOrder = {
    appProfile: null,
    appProfileDetails: null,
    entityDetails: null,
    sector: null,
    sectorDetail: null,
    environment: null,
    region: null,
    regionDisplayName: null,
    landingZone: null,
    landingZoneDetails: null,
    serverCollation: null,
    sqlDatabaseName: '',
    deploymentType: null,
    logicalHostExist: null,
    logicalHost: null,
    elasticPoolExist: null,
    elasticPoolName: '',
    geoReplication: true,
    deploymentModel: null,
    deploymentModelDetails: null,
    serviceTiers: null,
    serviceTiersDetails: null,
    type: null,
    storageSize: null,
    storageSizeDetails: null,
    vCoresCount: null,
    vCoreDetails: null,
    tags: [],
    tagsQc: null,
    estimatedCost: resetCostEstimateDetails,
    defaultTags: [],
    costCenter: null,
    costCenterDetails: null,
    billingEntity: null,
    billingEntityDetails: null,
    envDetails: null,
    hoursOfOperation: null,
    hoursOfOperationDetail: null,
    secondarylogicalHostExist: null,
    secondarylogicalHost: null,
    failOverGrpName: '',
    validationStatus: 'clearValidation',
    isTouched: false,
    zoneRedundancy: 'no',
    geoSecondaryReplication: 'no',
    sqlAccOwner: null,
    sqlAccOwnerDetails: null,
    isSqlAccExist: null,
    sqlServiceAccountName: null,
    sqlDbAuthentication: null,
};

export const resetForQuickConfig = {
    environment: null,
    region: null,
    regionDisplayName: null,
    serverCollation: null,
    sqlDatabaseName: '',
    deploymentType: null,
    logicalHostExist: null,
    logicalHost: null,
    elasticPoolExist: null,
    elasticPoolName: '',
    geoReplication: true,
    deploymentModel: null,
    deploymentModelDetails: null,
    serviceTiers: null,
    serviceTiersDetails: null,
    type: null,
    storageSize: null,
    storageSizeDetails: null,
    vCoresCount: null,
    vCoreDetails: null,
    tags: [],
    defaultTags: [],
    envDetails: null,
    estimatedCost: resetCostEstimateDetails,
    secondarylogicalHostExist: null,
    secondarylogicalHost: null,
    failOverGrpName: '',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    iszoneRedundancyModal: false,
    isGeoReplicationModal: false,
};

export const getDefaultDBName = (order) => {
    let sqlDbValue = `SQL${order?.sector.toLowerCase()}${order?.appProfile.substring(0, 3)}`;
    if (order?.environment.includes('Disaster Recovery')) {
        sqlDbValue = `${sqlDbValue}dr`;
    }
    if (order?.environment.includes('Preprod')) {
        sqlDbValue = `${sqlDbValue}pp`;
    }
    if (order?.environment.includes('Production')) {
        sqlDbValue = `${sqlDbValue}p`;
    }
    if (order?.environment.includes('Development')) {
        sqlDbValue = `${sqlDbValue}d`;
    }
    if (order?.environment.includes('QA')) {
        sqlDbValue = `${sqlDbValue}q`;
    }
    if (order?.environment.includes('TST')) {
        sqlDbValue = `${sqlDbValue}t`;
    }
    return sqlDbValue.toUpperCase();
};

export const getDefaultHostName = (order, primary = true) => {
    let logicalHostValue = `pep${order?.sector.toLowerCase()}${order?.appProfile.substring(0, 3)}${primary ? '1' : '2'}`;
    if (order?.environment.includes('Disaster Recovery')) {
        logicalHostValue = `${logicalHostValue}dr`;
    }
    if (order?.environment.includes('Preprod')) {
        logicalHostValue = `${logicalHostValue}pp`;
    }
    if (order?.environment.includes('Production')) {
        logicalHostValue = `${logicalHostValue}p`;
    }
    if (order?.environment.includes('Development')) {
        logicalHostValue = `${logicalHostValue}d`;
    }
    if (order?.environment.includes('QA')) {
        logicalHostValue = `${logicalHostValue}q`;
    }
    if (order?.environment.includes('TST')) {
        logicalHostValue = `${logicalHostValue}t`;
    }
    return logicalHostValue.toLowerCase();
};
export const getSecondaryHostName = (order) => {
    const hostnameval = order?.logicalHost;
    const idx = ['Disaster Recovery', 'Preprod']?.includes(order?.environment);
    const modifiedVal = hostnameval?.slice(-2, -1);
    const modifiedVal2 = hostnameval?.slice(-3, -2);
    const validx = idx ? modifiedVal2 : modifiedVal;
    const postFixVal = idx ? hostnameval?.slice(-2) : hostnameval?.slice(-1);

    const secondaryhostNameValue = `${hostnameval?.slice(-hostnameval.length, idx ? '-3' : '-2')}${validx !== '2' ? '2' : '3'}${postFixVal}`;

    return secondaryhostNameValue;
};
export const getFailoverGroupName = (order) => {
    const hostnameval = order?.logicalHost;
    const idx = ['Disaster Recovery', 'Preprod']?.includes(order?.environment);
    const modifiedVal = `${hostnameval?.slice(-hostnameval.length, idx ? '-3' : '-2')}geo`;

    return modifiedVal;
};

export const validateOrder = (order, validationStates) => {
    const {
        isMandatoryTags,
        isLogicalHostValid,
        isLogicalHostAlreadyExists,
        isSQLDBAlreadyExists,
        isSQLDBValid,
        applicationNameValid,
        appShortNameValid,
        appShortNameExist,
        isPoolNameExist,
        isElasticpoolNameVaild,
        sqlServiceAccountNameValid,
    } = validationStates;
    if (!order?.appProfile) return { isValid: false, message: 'App profile is missing' };
    if (!order?.environment) return { isValid: false, message: 'Environment is missing' };
    if (!order?.sector) return { isValid: false, message: 'Sector is missing' };
    if (!order?.region) return { isValid: false, message: 'Region is missing' };
    if (!order?.landingZoneDetails) return { isValid: false, message: 'Landing Zone is missing' };
    if (order?.isSqlAccExist === 'no' && !order?.applicationName) return { isValid: false, message: 'Application Name is missing' };
    if (order?.isSqlAccExist === 'no' && !order?.appShortName) return { isValid: false, message: 'Application Short Name is missing' };
    if (!order?.serverCollation) return { isValid: false, message: 'Server collation is missing' };
    if (!order?.sqlDatabaseName) return { isValid: false, message: 'SQL Database Name is missing' };
    if (!order?.deploymentType) return { isValid: false, message: 'Deployment Type is missing' };
    if (!order?.logicalHostExist) return { isValid: false, message: 'Logical Host Exist is missing' };
    if (!order?.logicalHost) return { isValid: false, message: 'Logical Host is missing' };
    if (!order?.sqlDbAuthentication) return { isValid: false, message: 'SQL Database Authentication is missing' };
    if (order?.sqlDbAuthentication === 'yes' && !order?.isSqlAccExist) return { isValid: false, message: 'SQL Account Existing value is missing' };
    if (order?.sqlDbAuthentication === 'yes' && order?.isSqlAccExist === 'yes' && !order?.sqlServiceAccountName)
        return { isValid: false, message: 'SQL Service Account Name is missing' };
    if (order?.sqlDbAuthentication === 'yes' && order?.isSqlAccExist === 'no' && !order?.sqlAccOwner)
        return { isValid: false, message: 'SQL Account Owner is missing' };
    if (order?.deploymentType === 'Azure SQL Elastic Pool' && !order?.elasticPoolName) return { isValid: false, message: 'Elastic Pool Name is missing' };
    if (!order?.deploymentModel) return { isValid: false, message: 'Deployment Model is missing' };
    if (order?.deploymentModel === 'VCore Based') {
        const missingProperty = ['serviceTiers', 'vCoresCount', 'storageSize', 'storageSizeDetails'].find((property) => !order?.[property]);
        if (missingProperty) {
            return { isValid: false, message: `${missingProperty} is Missing` };
        }
    }
    if (order?.deploymentModel === 'DTU Based') {
        const missingProperty = ['serviceTiers', 'type', 'storageSize', 'storageSizeDetails'].find((property) => !order?.[property]);
        if (missingProperty) {
            return { isValid: false, message: `${missingProperty} is Missing` };
        }
    }
    // if (order?.deploymentModel === 'DTU Based' && (!order?.serviceTiers || !order?.type || !order?.storageSize || !order?.storageSizeDetails?.storageValue))
    //     return { isValid: false, message: 'Service Tiers, Type, Storage Size is missing' };
    if (['VCore Based', 'DTU Based']?.includes(order?.deploymentModel) && !order?.storageSizeDetails?.storageValue)
        return { isValid: false, message: 'Storage Size Details is missing' };
    if (
        order?.deploymentModel === 'VCore Based' &&
        (!order?.serviceTiers || !order?.vCoresCount || !order?.storageSize || !order?.storageSizeDetails?.storageValue)
    )
        return { isValid: false, message: 'Service Tiers, vCores Count, Storage Size is missing' };
    if (order?.geoSecondaryReplication === 'yes' && (!order?.secondarylogicalHost || !order?.failOverGrpName))
        return { isValid: false, message: 'Secondary Logical Host, Failover Group Name is missing' };
    if (!order?.costCenter) return { isValid: false, message: 'Cost Center is missing' };
    if (!order?.billingEntity) return { isValid: false, message: 'Billing Entity is missing' };

    if (isMandatoryTags) return { isValid: false, message: 'Mandatory Tags are missing' };
    if (isLogicalHostValid) return { isValid: false, message: 'Logical Host is invalid' };
    if (isLogicalHostAlreadyExists) return { isValid: false, message: 'Logical Host already exists' };
    if (isSQLDBAlreadyExists) return { isValid: false, message: 'SQL Database already exists' };
    if (isSQLDBValid) return { isValid: false, message: 'SQL Database is invalid' };
    if (order?.isSqlAccExist === 'no' && !applicationNameValid) return { isValid: false, message: 'Application Name is invalid' };
    if (order?.isSqlAccExist === 'no' && !appShortNameValid) return { isValid: false, message: 'App Short Name is invalid.' };
    if (order?.isSqlAccExist === 'no' && appShortNameExist) return { isValid: false, message: 'App Short Name already exists.' };
    if (isPoolNameExist) return { isValid: false, message: 'Elastic Pool Name already exists.' };
    if (!isElasticpoolNameVaild) return { isValid: false, message: 'Elastic Pool Name is invalid.' };
    if (order?.isSqlAccExist === 'yes' && sqlServiceAccountNameValid === false) return { isValid: false, message: 'SQL Service Account Name is invalid.' };
    return { isValid: true, message: 'Order is valid' };
};
