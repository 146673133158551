import React from 'react';
import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import ResourceCard from 'pages/reference-implementation/provisioning/RI002O/components/Form/ResourceCard';
import AddComponent from 'pages/reference-implementation/provisioning/RI002O/components/Form/AddComponent';
import { compareComponentIdsLength } from 'pages/reference-implementation/provisioning/RI002O/utils';
import { validateCloning } from '../../utils/validateSelection';

const ResourceList = ({
    resources,
    handleAddResource,
    handleRemoveResource,
    handleConfigureResource,
    handleAddNewComponent,
    resourceMgmt,
    order,
    appShortNameValid,
    componentGroups,
}) => {
    const isAllComponentInserted = compareComponentIdsLength(resources, componentGroups)?.areEqual;
    const getCloneStatus = (newResource) => {
        const validateCloningErrors = validateCloning(newResource, resourceMgmt);

        return `${newResource?.componentId}-${newResource?.componentName}` in validateCloningErrors;
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 12, md: 12 }} className="customResourceCardContainer">
                {resources?.map((resource, resIdx) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <ResourceCard
                            key={resIdx}
                            resource={resource}
                            handleClone={handleAddResource}
                            handleRemove={handleRemoveResource}
                            index={resIdx}
                            handleConfigure={handleConfigureResource}
                            disableCloneIcon={getCloneStatus(resource)}
                            order={order}
                            appShortNameValid={appShortNameValid}
                        />
                    </Grid>
                ))}
                <Grid item xs={12} sm={12} md={4}>
                    <AddComponent
                        handleAddComponent={handleAddNewComponent}
                        order={order}
                        appShortNameValid={appShortNameValid}
                        isDisabled={isAllComponentInserted}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

ResourceList.propTypes = {
    resources: PropTypes.any,
    handleAddResource: PropTypes.func,
    handleRemoveResource: PropTypes.func,
    handleConfigureResource: PropTypes.func,
    handleAddNewComponent: PropTypes.func,
    resourceMgmt: PropTypes?.any,
    order: PropTypes?.any,
    appShortNameValid: PropTypes.any,
    componentGroups: PropTypes.any,
};

ResourceList.defaultProps = {
    resources: [],
    handleAddResource: () => {},
    handleRemoveResource: () => {},
    handleConfigureResource: () => {},
    handleAddNewComponent: () => {},

    // disableCloneIcon: {},
    resourceMgmt: [],
    order: {},
    appShortNameValid: false,
    componentGroups: [],
};
export default ResourceList;
