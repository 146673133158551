import { getLoggedUserClaims } from 'utils';

export const getPayload = ({ order = [], catalogCode, catalogServiceId }) => {
    const userClaims = getLoggedUserClaims();
    return {
        requestorGpid: userClaims?.accessToken?.claims?.gpid,
        applicationOwnerId: order?.appOwnerDetails?.gpid,
        entityDetails: {
            name: order?.appProfile,
            sector: order?.entityDetails?.sector || null,
        },
        estimatedCosts: order?.estimatedCost,
        catalogServices: order.instanceDetails.map((instanceDetail) => ({
            ...(catalogServiceId && { catalogServiceId }),
            cloudProvider: order?.cloudProvider,
            cloudService: order?.cloudService,
            catalogServiceCode: catalogCode,
            catalogType: order?.catalogType,
            sector: order?.sector || null,
            estimatedCost: order?.initCost,
            quantity: 1,
            catalogConfig: {
                personaType: instanceDetail?.personaType || null,
                sourceName: instanceDetail?.sourceName || null,
                sector: order?.sector || null,
                environment: order?.environment || null,
                region: order?.regionDisplayname || null,
                justification: order?.justification || null,
                dataClassification: order?.dataClassification || null,
                isaNumber: order?.isaNumber || null,
            },
        })),
    };
};
export default getPayload;
