import { styled } from '@mui/material/styles';
import { RadioGroup, Radio } from '@mui/material';

const SqlStyles = {
    iconStyle: { color: '#b0b0b0', marginRight: (theme) => theme.spacing(0.35), marginLeft: (theme) => theme.spacing(0.35) },
    boxBgColor: { bgcolor: (theme) => theme.palette.white },
    main: {
        wrapper: {
            // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: 'calc(100vh - 100px)',
        },
        WhiteColor: { color: (theme) => theme.palette.black.dark },
        mainBox: {
            // background: 'transparent linear-gradient(180deg, #3498DB 0%, #1A4C6E 100%) 0% 0% no-repeat padding-box',
            display: 'flex',
            flexDirection: 'column',
            // overflowY: 'scroll',
            width: '100%',
            height: '100%',
            // pt: (theme) => theme.spacing(2),
        },
        columnWrapper: { flexGrow: 1, mx: (theme) => theme.spacing(6.625) },
    },
    provisionHeader: {
        wrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        header: {
            // paddingTop: (theme) => theme.spacing(2),
            // paddingBottom: (theme) => theme.spacing(2),
            mx: (theme) => theme.spacing(6.625),
            mt: (theme) => theme.spacing(3.125),
        },
        costEstWrapper: {
            fontSize: (theme) => theme.spacing(1.75),
            background: 'linear-gradient(to top, #29323C, #485563)',
            color: '#e5f5fc',
            borderRadius: 0.5,
            marginRight: (theme) => theme.spacing(0.04375),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: (theme) => theme.spacing(1),
            paddingRight: (theme) => theme.spacing(1),
            paddingTop: (theme) => theme.spacing(0.5),
            paddingBottom: (theme) => theme.spacing(0.5),
        },
        costEstContent: {
            fontSize: (theme) => theme.spacing(2),
            fontWeight: (theme) => theme.typography.fontWeightBold,
        },
        circularProg: { color: (theme) => theme.palette.white, justifyContent: 'center', alignItems: 'center' },
        submitOrderBtn: {
            background: '#F99C24',
            fontSize: (theme) => theme.spacing(1.75),
            color: (theme) => theme.palette.white,
            width: (theme) => theme.spacing(18.75),
            height: (theme) => theme.spacing(4.375),
        },
        costSplitWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: (theme) => theme.spacing(1),
            marginBottom: (theme) => theme.spacing(1),
            mx: (theme) => theme.spacing(4),
        },
        msgBoxWrapper: {
            marginBottom: (theme) => theme.spacing(1),
            mx: (theme) => theme.spacing(4),
        },
        errorAlert: {
            mt: (theme) => theme.spacing(3.125),
            background: '#DB1E36',
            color: (theme) => theme.palette.white,
            '& .MuiAlert-icon': {
                color: (theme) => theme.palette.white,
            },
        },
        successAlert: {
            mt: (theme) => theme.spacing(3.125),
            background: '#003182',
            color: (theme) => theme.palette.white,
            '& .MuiAlert-icon': {
                color: (theme) => theme.palette.white,
            },
        },
        infoIcon: { fill: '#0047BA', color: '#ffffff', fontSize: '26px', marginRight: '10px !important', marginLeft: '5px' },
        warningIcon: { fill: '#D78B95', height: (theme) => theme.spacing(3), width: (theme) => theme.spacing(3), mt: (theme) => theme.spacing('-3px') },
        errorSqlAlert: {
            backgroundColor: '#AE172B',
            color: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            fontFamily: 'Roboto',
            '& .MuiAlert-action': {
                display: 'none',
            },
        },
    },
    serverRefresh: {
        textField: {
            input: {
                '&::placeholder': {
                    textAlign: { md: 'left', lg: 'left' },
                    fontSize: { md: (theme) => theme.spacing(1.625), lg: (theme) => theme.spacing(1.75) },
                    color: '#707070',
                    opacity: (theme) => theme.spacing(1),
                },
            },
            '& .MuiOutlinedInput-root': {
                display: { md: 'flex' },
                flexDirection: { md: 'row', lg: 'row' },
            },
        },
        radioGroup: {
            display: 'flex',
            justifyContent: 'right',
            width: 'inherit',
            fontSize: { md: (theme) => theme.spacing(1.25) },
            mr: { md: (theme) => theme.spacing(-2.25) },
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNo: {
            '& .MuiSvgIcon-root': {
                fontSize: { md: (theme) => theme.spacing(1.875), lg: (theme) => theme.spacing(2.25) },
            },
        },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    applicationDetails: {
        errormessageBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            minHeight: (theme) => theme.spacing(25),
        },
        errorColor: { color: '#000000' },
    },
    cartBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
        textTransform: 'none',
    },
    costSplit: {
        costSplitWrap: {
            display: 'flex',
            // alignItems: 'center',
            paddingTop: (theme) => theme.spacing(2),
            paddingBottom: (theme) => theme.spacing(2),
            paddingLeft: (theme) => theme.spacing(3),
            paddingRight: (theme) => theme.spacing(3),
            flexDirection: 'column',
            // borderBottom: (theme) => `1px solid ${theme.palette.gray.low}`,
            background: 'linear-gradient(to right, #093028, #237A57)',
            width: '100%',
            // height: '105px',
        },
        flexRow: {
            display: 'flex',
            width: '100%',
            marginBottom: (theme) => theme.spacing(1),
        },
        costSplitBlock: {
            width: '30%',
            display: 'flex',
            // justifyContent: 'space-between',
            '&:not(:first-child)': {
                paddingLeft: (theme) => theme.spacing('0.5rem'),
            },
            '&:not(:last-child)': {
                paddingRight: (theme) => theme.spacing('0.5rem'),
            },
        },
        costSplitBlockLeft: {
            display: 'flex',
            flexDirection: 'column',
        },
        costSplitTitle: {
            fontSize: (theme) => theme.spacing('1rem'),
            color: (theme) => theme.palette.white,
            variant: (theme) => theme.typography.body2,
        },
        costSplitSubTitle: {
            fontSize: (theme) => theme.spacing('0.875rem'),
            color: (theme) => theme.palette.white,
            variant: (theme) => theme.typography.body2,
        },
        estimatedCost: {
            fontSize: (theme) => theme.spacing('1.5rem'),
            marginLeft: (theme) => theme.spacing('auto'),
            fontWeight: (theme) => theme.spacing(62.8),
            marginRight: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.white,
            variant: (theme) => theme.typography.body2,
        },
        circularWrapper: { display: 'flex', width: '100%', height: (theme) => theme.spacing(11.875), alignItems: 'center', justifyContent: 'center' },
        circularProg: { color: '#ffffff' },
        divider: { backgroundColor: '#ffffff' },
        cancelIcon: { color: '#FFFFFF' },
        noteDivider: { width: '100%', backgroundColor: '#ffffff' },
        noteWrapper: { display: 'flex', marginTop: (theme) => theme.spacing(1), alignItems: 'flex-start' },
    },
    environmentContainer: {
        tagsBatch: { marginRight: (theme) => theme.spacing(1.5) },
        applicationBtn: {
            // border: '1px solid #00A0DD',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.cyan.main,
            color: (theme) => theme.palette.cyan[70],
        },
        outlinedBtn: {
            textTransform: 'none',
            color: (theme) => theme.palette.blue.main,
            borderWidth: (theme) => theme.spacing(0.25),
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.blue.main,
            '&:hover': {
                color: (theme) => theme.palette.cyan.main,
                borderWidth: (theme) => theme.spacing(0.25),
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.cyan.main,
                background: 'transparent',
            },
        },
        tagBtn: { textTransform: 'none' },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypography: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        newOrderBox: { textAlign: 'center', my: (theme) => theme.spacing(1), py: (theme) => theme.spacing(2) },
        newOrderBtn: { marginRight: (theme) => theme.spacing(2), textTransform: 'none' },
        message: { fontSize: '30px', fontWeight: 500, fontFamily: 'Stolzl', color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
        checkOutBtn: {
            width: '180px',
            textTransform: 'none',
            color: '#0047BA',
            border: '2px solid #0047BA',
            '&:hover': {
                color: '#00A0DD',
                borderWidth: '2px',
                border: '2px solid #00A0DD',
                backgroundColor: 'white',
            },
        },
    },
    tags: {
        wrapper: {
            minWidth: (theme) => theme.spacing(75),
            width: (theme) => theme.spacing(100),
            display: 'flex',
            flexDirection: 'column',
            height: (theme) => theme.spacing('100%'),
        },
        circularBox: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        circularProg: { color: (theme) => theme.palette.black },
        tagsBox: { flexGrow: 1, px: (theme) => theme.spacing(1) },
        tagsWrapper: { display: 'flex', flexDirection: 'column' },
        titleWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(2) },
        nameBox: { fontWeight: (theme) => theme.typography.fontWeightBold, width: { md: '31%', lg: '30.5%', xl: '29.5%' } },
        colanBox: { width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        valueBox: { width: (theme) => theme.spacing(50.625), fontWeight: 'bold', flexGrow: 1 },
        dataWrapper: { fontSize: (theme) => theme.spacing(1.875), display: 'flex', mb: (theme) => theme.spacing(1) },
        dataName: {
            display: 'flex',
            alignItems: 'center',
            width: '30%',
            border: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderRadius: (theme) => theme.spacing(0.5),
            borderColor: 'gray.lowMedium',
            py: (theme) => theme.spacing(0.5),
            px: (theme) => theme.spacing(1.5),
        },
        dataValue: { display: 'flex', alignItems: 'center', width: (theme) => theme.spacing(0.75), mx: (theme) => theme.spacing(1) },
        customBox: { flexGrow: 1 },
        actionBox: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        resetBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            textTransform: 'none',
            color: '#6E6F6F',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        testTrm: { textTransform: 'none' },
        activeSelectField: {
            background: 'transparent',
            '& label.MuiFormLabel-filled,label.MuiInputLabel-shrink': {
                // background: '#00709B',
                color: '#0047BA',
                padding: '0 2px',
            },
            '& label.Mui-disabled+div fieldset': {
                border: ' 1px solid #E9E9E9',
            },
            '& label.MuiFormLabel-filled+div fieldset': {
                border: ' 1px solid #0047BA',
            },
            '& label.Mui-focused+div fieldset': {
                border: ' 2px solid #0047BA !important',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0047BA',
            },
        },
    },
    deploymentType: {
        wrapper: { alignItems: 'center', justifyContent: 'center' },
        iconStyle: { color: (theme) => theme.palette.gray.main },
    },
    selectCOMicon: {
        wrapper: { alignItems: 'center', justifyContent: 'center' },
        iconStyle: { color: (theme) => theme.palette.gray.main },
    },
    logicalHost: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        topLeftNamingRule: { borderRight: '1px solid #AFB8CC', mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomNamingRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', vaiant: (theme) => theme.typography.caption.fontSize },
        toast: {
            display: 'flex',
            background: '#ffffff',
            color: (theme) => theme.palette.black,
            justifyContent: 'center',
            alignItems: 'center',
            padding: (theme) => theme.spacing(1),
        },
        reportIcon: {
            color: '#F99C24',
            margin: (theme) => theme.spacing(1),
        },
    },
    elasticPool: { background: '#fff', marginRight: (theme) => theme.spacing(1) },
    vCoreCount: { wrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
    costCenter: {
        selectStyle: { width: '100%' },
    },
    quickConfig: {
        paper: {
            //  background: () => '#e8f0f8',
            border: '#00358f 1px solid',
        },
        wrapper: {
            background: '#FBFBFB 0% 0% no-repeat padding-box',
            boxShadow: 'none',
            color: (theme) => theme.palette.white,
            borderRadius: '5px',
            borderWidth: (theme) => theme.spacing(0.125),
            borderStyle: 'solid',
            borderColor: '#E8E8E8',
        },
        content: {
            background: '#fff',
            margin: (theme) => theme.spacing(0.25),
            marginTop: 0,
            color: '#333',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
        labelValueStyle: { padding: (theme) => theme.spacing(0.5, 1) },
        AccorSummary: { width: '93%', flexShrink: 0 },
        selectBtnStyle: {
            marginLeft: (theme) => theme.spacing('auto'),
            display: 'flex',
            alignItems: 'flex-end',
            pr: (theme) => theme.spacing(1.5),
            pb: (theme) => theme.spacing(1.5),
        },
        envTitle: { backgroundColor: '#0047BA', borderRadius: '5px 5px 0px 0px' },
        selectedBtn: {
            textTransform: 'none',
            px: 1,
            '&.Mui-disabled': {
                backgroundColor: '#01408B',
                color: 'white',
            },
        },
        emptycontainer: { backgroundColor: '#FFFFFF' },
        emptymsg: { fontFamily: 'Stolzl', fontSize: '20px', color: '#000000', fontWeigtht: 'medium' },
        emptySuggestion: { fontFamily: 'Roboto', fontSize: '16px', fontWeight: 'normal' },
    },

    confirmDD: {
        dialogContentBox: { textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' },
        dialogActionBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2) },
        dialogNvmeActionBox: { display: 'flex', justifyContent: 'center', mt: (theme) => theme.spacing(2), flexDirection: 'column', alignItems: 'center' },
        dialogNoBtn: {
            marginLeft: (theme) => theme.spacing(1.25),
            textTransform: 'none',
            border: '2px solid #707070',
            '&:hover': {
                color: '#6E6F6F',
                border: '2px solid #707070',
                background: 'transparent',
            },
        },
        icon: { color: '#d02424' },
    },
    zoneredundancyradioBtnNoForm: {
        '& .css-j204z7-MuiFormControlLabel-root label': {
            marginRight: (theme) => theme.spacing(1.25),
        },
    },
    adinfoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', width: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
};

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    // justifyContent: 'right',
    marginLeft: '5px',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));
export default SqlStyles;
