import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { OrderPayload } from 'pages/reference-implementation/provisioning/RI002O/store';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { RIStyles } from 'pages/reference-implementation/Style';

const BusinessContact = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [options, setOptions] = React.useState([]);
    const [searched, setSearched] = React.useState(false);
    const businessContactApi = useMutation(() => axios.get(`${endPoints.riServices.COMMON.appOwner}?key=${ccInputValue}`));
    const businessContactPostApi = useMutation((payload) => axios.get(`${endPoints.riServices.COMMON.appOwner}?key=${payload}`));

    const setBusinessContact = (businessContact, businessContactDetails) => {
        setOrder({
            ...order,
            businessContact,
            businessContactDetails,
        });
    };
    const resetOptions = () => {
        setOptions([]);
        setBusinessContact(null, null);
    };
    const fetch = React.useMemo(
        () =>
            throttle(() => {
                businessContactApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };
    const isExistsInOptions = (businessContactValue) => options.find((option) => businessContactValue === option.name);
    React.useEffect(() => {
        if (options && options?.length === 1 && order?.businessContactDetails && !order?.businessContact) {
            setOrder({ ...order, businessContact: options?.[0].label, businessContactDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.businessContactDetails && !order?.businessContact) {
            const businessContactObj = isExistsInOptions(order?.businessContactDetails?.gpid);
            setOrder({ ...order, businessContact: businessContactObj?.label, businessContactDetails: businessContactObj });
        }
    }, [options]);
    React.useEffect(() => {
        if (businessContactApi?.data?.data) {
            const combinedValue = businessContactApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [businessContactApi?.data?.data]);

    React.useEffect(() => {
        if (businessContactPostApi?.data?.data) {
            const combinedValue = businessContactPostApi?.data?.data?.map((app) => ({
                label: `${app.gpid} - ${app.name}`,
                gpid: `${app.gpid}`,
                name: `${app.name}`,
                email: `${app.email}`,
            }));
            setOptions(combinedValue);
        }
    }, [businessContactPostApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setBusinessContact(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (!order?.businessContact && order?.businessContactDetails) {
            businessContactPostApi.mutate(order?.businessContactDetails?.gpid);
        }
    }, [order?.businessContact, order?.businessContactDetails]);

    React.useEffect(() => {
        if (!order?.businessContact && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.businessContact, order?.isTouched]);
    React.useEffect(() => {
        if (!order?.businessContact && !order?.businessContactDetails) {
            setOptions([]);
        }
    }, [order?.businessContact, order?.businessContactDetails]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.businessContact) || isTouched) {
            return 'validate';
        }
        return '';
    };
    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };
    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <Autocomplete
                    data-testid="business-contact-search"
                    key="businessContactDetails"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    loading={businessContactApi?.isLoading}
                    value={order?.businessContact || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                    }}
                    className={validateInput()}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    //    filterOptions={filterOptions}

                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    isOptionEqualToValue={(option, value1) => option?.label === value1}
                    sx={{ width: '100%' }}
                    onBlur={() => {
                        if (!value || !order?.businessContact) {
                            setSearched(false);
                            setOptions([]);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                        }
                    }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label={<InputLabel label="Business Contact" isRequired />}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={RIStyles?.form?.searchIcon}
                                            onClick={() => {
                                                setSearched(true);
                                                handleSearch();
                                            }}
                                            data-testid="search-btn"
                                        />
                                    </>
                                ),
                            }}
                            fullWidth
                        />
                    )}
                />
            </Box>
        </>
    );
};

export default React.memo(BusinessContact);
