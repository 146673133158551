import React, { lazy, Suspense } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    OrderPayload,
    isProjectIdValid,
    // adGroupErrorMsg,
    showPreviewOrder,
    previewDetails,
    isBusinessNameValid,
    isBudgetCodeValid,
    isAppShortNameValid,
    isAppShortNameExist,
    previewOrderError,
    // contributorErrorMsg,
    isBusinessAppValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
    isAppUrlValid,
    isValidOwners,
    // isAppContextPathValid,
    isAppNameValid,
    isValidappSupportMailId,
} from 'pages/reference-implementation/provisioning/RI002O/store';
import { RIStyles } from 'pages/reference-implementation/Style';
import Btn from 'components/button';
import { getpreviewDetails } from 'pages/reference-implementation/provisioning/RI002O/utils/preview';
import { isReadyToSubmitNew } from 'pages/reference-implementation/provisioning/RI002O/utils/validation';
import PropTypes from 'prop-types';
import { customToast } from 'api/utils';

const ErrorBanner = lazy(() => import('pages/reference-implementation/components/error-banner'));

export const PreviewOrder = (props) => {
    const { isBgApiLoading } = props;
    const [isLoading, setLoading] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const projectIdValid = useRecoilValue(isProjectIdValid);
    // const adGroupError = useRecoilValue(adGroupErrorMsg);
    // const contributorError = useRecoilValue(contributorErrorMsg);
    const businessUnitValid = useRecoilValue(isBusinessNameValid);
    const budgetCodeValid = useRecoilValue(isBudgetCodeValid);
    const appShortNameValid = useRecoilValue(isAppShortNameValid);
    const appShortNameExist = useRecoilValue(isAppShortNameExist);
    const businessAppValid = useRecoilValue(isBusinessAppValid);
    const nonPrivilegedADGroupValid = useRecoilValue(isNonPrivilegedADGroupValid);
    const privilegedADGroupValid = useRecoilValue(isPrivilegedADGroupValid);
    const appUrlValid = useRecoilValue(isAppUrlValid);
    const validOwners = useRecoilValue(isValidOwners);
    const appNameValid = useRecoilValue(isAppNameValid);
    // const appContextPathValid = useRecoilValue(isAppContextPathValid);
    const [errorBannerOpen, setErrorBannerOpen] = React.useState(false);
    const [, setShowPreview] = useRecoilState(showPreviewOrder);
    const [, setPreviewDetails] = useRecoilState(previewDetails);
    const [previewError, setPreviewError] = useRecoilState(previewOrderError);
    const validApplicationSupportMail = useRecoilValue(isValidappSupportMailId);

    const handleClickErrorBanner = () => {
        setErrorBannerOpen(true);
    };
    const handleCloseErrorBanner = () => {
        setErrorBannerOpen(false);
    };

    const setPreview = () => {
        setShowPreview('pre-preview-order');
        const mockPreview = {
            data: {
                subscription: 'pep-cmp-01-sub',
                location: 'East US',
                environment: 'Development',
                resources: {
                    'resource-group': {
                        name: 'pep-apptest1-cmp-dev-eus-<sequenceNumber>-rg',
                        'role-assignments': [
                            {
                                assignee: 'Team.support.CMP.NonProd.apptest1',
                                'assignee-type': 'Privileged AD group',
                                'access-levels': ['Contributor'],
                            },
                            {
                                assignee: 'Team.Support.EIAP.K8S.Admin',
                                'assignee-type': 'Privileged AD group',
                                'access-levels': ['Reader'],
                            },
                            {
                                assignee: 'Team.app.CMP.NonProd.apptest1',
                                'assignee-type': 'AD Group',
                                'access-levels': ['Reader'],
                            },
                            {
                                assignee: 'Team.Support.EIAP.Global.NOSQL.Admin',
                                'assignee-type': 'Privileged AD group',
                                'access-levels': ['Contributor'],
                            },
                            {
                                assignee: 'Team.Support.DPS.NonProd.AzureSub.Admin',
                                'assignee-type': 'Privileged AD group',
                                'access-levels': ['Contributor'],
                            },
                        ],
                        tags: [
                            {
                                key: 'serviceName',
                                value: 'cloud-marketplace-cmp-10',
                            },
                            {
                                key: 'OrgGrpCodeProjectCode',
                                value: 'CMP-apptest1',
                            },
                            {
                                key: 'ProjectName',
                                value: 'testapp',
                            },
                            {
                                key: 'BusinessSector',
                                value: 'CMP',
                            },
                            {
                                key: 'BillingInformation',
                                value: 'H-98765',
                            },
                            {
                                key: 'SupportContact',
                                value: 'Vishwanath.Ganesh.Contractor@pepsico.com',
                            },
                            {
                                key: 'BusinessContact',
                                value: 'Vishwanath.Ganesh.Contractor@pepsico.com',
                            },
                            {
                                key: 'pep-environment',
                                value: 'Development',
                            },
                            {
                                key: 'Environment',
                                value: 'Development',
                            },
                            {
                                key: 'pep-cmdb-app-service-name',
                                value: 'CLOUD MARKETPLACE(CMP) NON-PROD',
                            },
                            {
                                key: 'pep-cmdb-app-service-id',
                                value: 'dc4d9d7c4726151052293238436d4327',
                            },
                            {
                                key: 'pep-cmdb-bus-app-name',
                                value: 'CLOUD MARKETPLACE(CMP)',
                            },
                            {
                                key: 'pep-cmdb-bus-app-id',
                                value: '7b16ee101b6cb410fa1cb99f034bcb87',
                            },
                        ],
                    },
                    'key-vault': [
                        {
                            name: 'apptest1deveus<sequenceNumber>-kv',
                            tags: [
                                {
                                    key: 'serviceName',
                                    value: 'cloud-marketplace-cmp-10',
                                },
                            ],
                            'access-Policy': [
                                {
                                    assignee: 'Team.support.CMP.NonProd.apptest1',
                                    'assignee-type': 'Privileged AD group',
                                    'access-levels': ['admin'],
                                },
                                {
                                    assignee: 'Team.app.CMP.NonProd.apptest1',
                                    'assignee-type': 'AD Group',
                                    'access-levels': ['reader'],
                                },
                            ],
                        },
                    ],
                    'aks-namespace': [
                        {
                            'namespace-name': 'pep-cmp-cmp-apptest1-dev',
                            'cluster-name': 'pep-cmp-nonprod-eus-01-aks',
                            'acr-name': 'pepeiapnonprodcommonacr.azurecr.io',
                            'role-assignments': [
                                {
                                    assignee: 'Team.support.CMP.NonProd.apptest1',
                                    'assignee-type': 'Privileged AD group',
                                    'access-levels': null,
                                },
                            ],
                        },
                    ],
                    others: {
                        userAssignedIdentityName: 'pep-apptest1-cmp-dev-eus-02-mi',
                    },
                },
                'estimated-cost': {
                    'total-one-time-cost': {
                        frequency: 'One Time',
                        currency: 'USD',
                        'estimated-cost': 0.0,
                        item: 'Total One Time',
                    },
                    'total-recurring-cost': {
                        frequency: 'monthly',
                        currency: 'USD',
                        'estimated-cost': 335.19,
                        item: 'Total Recurring',
                    },
                    details: [
                        {
                            estCost: 0.02,
                            frequency: 'Per 10000 Operations',
                            currency: 'USD',
                            item: 'Key Vault',
                        },
                        {
                            estCost: 335.17,
                            frequency: 'monthly',
                            currency: 'USD',
                            item: 'Aks Namespace',
                            notes: 'Aks Namespace monthly cost includes Software: 54.17, Hardware: 163.58, Labor: 100.0 and Tax: 17.42',
                        },
                        {
                            estCost: 0.0,
                            frequency: 'monthly',
                            currency: 'USD',
                            item: 'Azure Support',
                            notes: '5.78% on azure costs',
                        },
                        {
                            estCost: 0.0,
                            frequency: 'monthly',
                            currency: 'USD',
                            item: 'Shared Service',
                            notes: '8.00% on azure costs',
                        },
                        {
                            estCost: 0.0,
                            frequency: 'monthly',
                            currency: 'USD',
                            item: 'Tax on Cloud',
                            notes: '8.25% on (azure cost + azure support cost + shared service cost)',
                        },
                    ],
                    notes: [''],
                },
                'tech-input-json': {
                    instance_repo_uri: 'https://dev.azure.com/PepsiCoIT/CMP_ESS_Full_Stack_Patterns/_git/cv-riinst01',
                    app_name: 'testapp1',
                    ri_name: 'ri003o',
                    env: 'dev',
                    tech_input_json: {
                        az_sub_id: 'dcae03ca-ca66-40ff-a58e-a8585fd62d6d',
                        az_sector: 'CMP',
                        az_location: 'eastus',
                        az_env: 'dev',
                        az_tags: {
                            'pep-cmdb-app-service-id': 'dc4d9d7c4726151052293238436d4327',
                            ProjectName: 'testapp',
                            'pep-cmdb-bus-app-id': '7b16ee101b6cb410fa1cb99f034bcb87',
                            BusinessSector: 'CMP',
                            SupportContact: 'Vishwanath.Ganesh.Contractor@pepsico.com',
                            serviceName: 'cmp',
                            'pep-environment': 'Development',
                            'pep-cmdb-app-service-name': 'CLOUD MARKETPLACE(CMP) NON-PROD',
                            OrgGrpCodeProjectCode: 'CMP-apptest1',
                            BillingInformation: 'H-007974',
                            Environment: 'Development',
                            BusinessContact: 'Vishwanath.Ganesh.Contractor@pepsico.com',
                            'pep-cmdb-bus-app-name': 'CLOUD MARKETPLACE(CMP)',
                            CmpOrderId: '<CmpOrderId>',
                        },
                        az_rg_name: 'pep-apptest1-cmp-dev-eus-02-rg',
                        az_rg_role_assignments: {
                            Contributor: ['NA', '15ba4e4c-7632-47e7-82a4-6dabf6d922a8', 'c7e72be3-40e8-49c5-a775-a1cd951340f2'],
                            Reader: ['367dfb5d-8aed-4dcf-9366-6024024bfb94', 'NA'],
                        },
                        az_kv_name: 'apptest1deveus02-kv',
                        az_kv_accesspolicy: {
                            reader: ['NA'],
                            admin: ['NA'],
                        },
                        az_blob_storage_flag: false,
                        az_sql_flag: false,
                        az_aks_cluster_name: 'pep-cmp-nonprod-eus-01-aks',
                        az_aks_sub_id: 'dcae03ca-ca66-40ff-a58e-a8585fd62d6d',
                        az_aks_snet_id:
                            '/subscriptions/dcae03ca-ca66-40ff-a58e-a8585fd62d6d/resourceGroups/pep-network-cmp-eus-01-rg/providers/Microsoft.Network/virtualNetworks/pep-cmp-eus-01-vnet/subnets/pep-aks-eus-01-subnet',
                        az_ans_snet_id:
                            '/subscriptions/dcae03ca-ca66-40ff-a58e-a8585fd62d6d/resourceGroups/pep-network-cmp-eus-01-rg/providers/Microsoft.Network/virtualNetworks/pep-cmp-eus-01-vnet/subnets/pep-aks-eus-01-subnet',
                        az_aks_namespace_name: 'pep-cmp-cmp-apptest1-dev',
                        az_aks_ad_group_object_id: 'NA',
                        az_aks_department: 'CMP',
                        az_istio_gateway_url: 'cmp.gw01.aks01.eus.nonprod.azure.intra.pepsico.com',
                        az_aks_rg_name: 'pep-cmp-nonprod-eus-k8s-01-rg',
                        az_acr_name: 'pepeiapnonprodcommonacr.azurecr.io',
                        az_aks_cluster_oidc_issuer_url:
                            'https://eastus.oic.prod-aks.azure.com/42cc3295-cd0e-449c-b98e-5ce5b560c1d3/c1030c7c-8965-45fe-a473-c9c0634d4f12/',
                        az_aks_nsg_resource_id:
                            '/subscriptions/dcae03ca-ca66-40ff-a58e-a8585fd62d6d/resourceGroups/pep-network-cmp-nsg-eus-01-rg/providers/Microsoft.Network/networkSecurityGroups/pep-aks-eus-01-nsg',
                        az_aks_pe_rg_name: 'ess-cv-network-eus-rg',
                        az_aks_pe_snet_id:
                            '/subscriptions/dcae03ca-ca66-40ff-a58e-a8585fd62d6d/resourceGroups/ess-cv-network-eus-rg/providers/Microsoft.Network/virtualNetworks/ess-cv-spoke-vnet/subnets/pe-subnet',
                        az_aks_tags: {
                            serviceName: 'cmp',
                            application_service: 'CLOUD MARKETPLACE(CMP) NON-PROD',
                            'pep-cmdb-app-service-id': 'dc4d9d7c4726151052293238436d4327',
                            business_application_service: 'CLOUD MARKETPLACE(CMP)',
                            'pep-cmdb-bus-app-id': '7b16ee101b6cb410fa1cb99f034bcb87',
                            'pep-environment': 'Development',
                        },
                        cv_external_url: false,
                        az_redis_flag: false,
                        az_eventhub_flag: false,
                        az_mysqlflexi_flag: false,
                        az_postgresflexi_flag: false,
                        az_servicebus_flag: false,
                        az_cosmossql_flag: false,
                        az_cosmosdb_cassandra_flag: false,
                        az_cosmosdb_gremlin_flag: false,
                        az_cosmosdb_mongo_flag: false,
                        az_cosmosdb_postgresql_flag: false,
                        az_cosmosdb_table_flag: false,
                        az_user_mi_name: 'pep-apptest1-cmp-dev-eus-02-mi',
                        az_data_classification: 'Internal',
                    },
                },
            },
            messages: ['Successfully returned the RI Optional preview details'],
            traceId: 'b37e3a707d070ca3963a57cfa7dddd6f',
            isSuccess: true,
        };
        setOrder({
            ...order,
            environmentDetails: mockPreview?.data,
            prePreviewOrder: getpreviewDetails({
                order: {
                    ...order,
                    environmentDetails: mockPreview?.data,
                },
            }),
        });
    };

    const handleValidation = () => {
        setPreview();
        if (order === 123) {
            document.getElementById('applayoutbox').scrollTop = 0;
            if (previewError?.isPreviewError) {
                setOrder({ ...order, isTouched: false });
                customToast(previewError?.message);
                setLoading(false);
            } else {
                setLoading(true);
                const formValidation = isReadyToSubmitNew({
                    order,
                    businessAppValid,
                    projectIdValid,
                    businessUnitValid,
                    budgetCodeValid,
                    appShortNameValid,
                    appShortNameExist,
                    nonPrivilegedADGroupValid,
                    privilegedADGroupValid,
                    appNameValid,
                    appUrlValid,
                    validApplicationSupportMail,
                    validOwners,
                });
                if (formValidation?.isValid && order?.environmentDetails) {
                    setPreviewDetails(getpreviewDetails({ order }));
                    setShowPreview('pre-preview-order');
                    setLoading(false);
                } else {
                    setOrder({ ...order, isTouched: true });
                    setPreviewError({ ...previewError, isPreviewError: false, message: '' });
                    handleClickErrorBanner();
                    setLoading(false);
                }
            }
        }
    };
    return (
        <Suspense
            fallback={
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="calc(100vh - 100px)" width="100%">
                    <CircularProgress color="inherit" size={16} sx={{ color: '#000000' }} />
                    <Typography variant="body2" sx={{ color: '#000000' }}>
                        Loading
                    </Typography>
                </Box>
            }
        >
            {' '}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Btn
                    size="medium"
                    onClick={() => handleValidation()}
                    variant="outlined"
                    disabled={isLoading || isBgApiLoading}
                    sx={RIStyles?.CostSummary?.previewButton}
                    color="cmpBlack"
                    data-testid="add-to-cart-btn"
                >
                    {isLoading && !isBgApiLoading ? <CircularProgress color="inherit" size={16} /> : 'Preview Order Before Submitting'}
                </Btn>
            </Box>
            {errorBannerOpen && (
                <ErrorBanner
                    show={errorBannerOpen}
                    message="Error! Please fill out the missing values marked with the * asterisk and click preview."
                    handleCloseCallback={handleCloseErrorBanner}
                />
            )}
        </Suspense>
    );
};

PreviewOrder.propTypes = {
    isBgApiLoading: PropTypes.bool,
};

PreviewOrder.defaultProps = {
    isBgApiLoading: false,
};

export default React.memo(PreviewOrder);
