import { validateInstanceDetails } from './index';

export const isReadyToSubmit = (order = {}) => {
    const mandatoryProperties = [
        'appProfile',
        'environment',
        'sector',
        'region',
        'appOwner',
        'appOwnerDetails',
        'appProfileDetails',
        'isaNumber',
        'dataClassification',
        'justification',
    ];

    const missingProperty = mandatoryProperties.find((property) => !order?.[property]);
    if (missingProperty) {
        return { isValid: false, message: `Missing mandatory property: ${missingProperty}` };
    }
    const validation = order.instanceDetails && validateInstanceDetails(order.instanceDetails);

    if (!validation.isValid) {
        return {
            isValid: false,
            message: `Validation failed: ${validation.message}`,
        };
    }

    // If none of the conditions above are met, it means all checks have passed
    return { isValid: true, message: 'All checks passed successfully' };
};

export default {};
