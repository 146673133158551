import React from 'react';
import { Grid, Stack, Box } from '@mui/material';
import Form from 'pages/sso-okta/components/Form';
import { FormCard } from 'pages/provisioning/_components';

const OIDCForm = () => {
    return (
        <Grid container spacing={3.125}>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <FormCard
                        title="Project"
                        showTooltip
                        placement="right"
                        maxWidth="800px"
                        tooltipmessage={
                            <ul>
                                <li>
                                    <Box sx={{ textWrap: 'wrap' }}>
                                        OIDC-
                                        <a href="https://www.okta.com/openid-connect" target="_blank" rel="noreferrer" style={{ wordWrap: 'wrap' }}>
                                            https://www.okta.com/openid-connect
                                        </a>
                                    </Box>
                                </li>
                                <li>
                                    <Box width="100%" sx={{ textWrap: 'nowrap' }}>
                                        OIDC OAUTH2.0 - Machine2Machine(API Service) -
                                        <a href="https://auth0.com/blog/using-m2m-authorization/" target="_blank" rel="noreferrer">
                                            https://auth0.com/blog/using-m2m-authorization/
                                        </a>
                                    </Box>
                                </li>
                                <li>
                                    <Box width="100%" sx={{ textWrap: 'nowrap' }}>
                                        SAML-
                                        <a href="https://developer.okta.com/docs/concepts/saml/" target="_blank" rel="noreferrer">
                                            https://developer.okta.com/docs/concepts/saml/
                                        </a>
                                    </Box>
                                </li>
                            </ul>
                        }
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Form.RequestType />
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Form.Sector />
                                    <Form.BusinessApplication />
                                    <Form.ApplicationName />
                                    <Form.ApplicationType />
                                    <Form.AppOwnerSearch />
                                    <Form.DataClassification />
                                    <Form.RoleWrapper />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <Form.Environments />
                                    <Form.ApplicationService />
                                    <Form.ApplicationShortName />
                                    <Form.ApplicationUrl />
                                    <Form.LogoutUrl />
                                    <Form.AuthorizationRolesRadio />
                                </Stack>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default OIDCForm;
