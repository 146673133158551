import React from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PropTypes from 'prop-types';
import { RI003Styles, CustomAddPaper } from 'pages/reference-implementation/provisioning/RI002O/style';
import { checkValidFields } from 'pages/reference-implementation/provisioning/RI002O/utils/checkValidFields';

const AddComponent = ({ handleAddComponent, order, appShortNameValid, isDisabled }) => {
    const validation = checkValidFields(order, appShortNameValid);
    const disabled = !validation.isValid || isDisabled;
    return (
        <CustomAddPaper evaluation={0} onClick={disabled ? undefined : handleAddComponent} disabled={disabled}>
            <Box sx={RI003Styles?.ResourceMgmtSectionStyle?.addComponentStyle} data-testid="add-component">
                <IconButton disabled={disabled}>
                    <AddCircleOutlineOutlinedIcon
                        size={25}
                        sx={{
                            ...RI003Styles?.ResourceMgmtSectionStyle?.addIconStyle,
                            color: disabled ? '#A5A5A5' : '#1976d2',
                        }}
                    />
                </IconButton>
                <Typography
                    sx={{
                        ...RI003Styles?.ResourceMgmtSectionStyle?.addTextStyle,
                        color: disabled ? '#A5A5A5' : '#1976d2',
                    }}
                >
                    Add Optional Services
                </Typography>
            </Box>
        </CustomAddPaper>
    );
};

AddComponent.propTypes = {
    handleAddComponent: PropTypes.func,
    order: PropTypes.any,
    appShortNameValid: PropTypes.bool.isRequired,
    isDisabled: PropTypes.any,
};

AddComponent.defaultProps = {
    handleAddComponent: () => {},
    order: {},
    isDisabled: false,
};
export default AddComponent;
