import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, CircularProgress } from '@mui/material';
import { RIStyles } from 'pages/reference-implementation/Style';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { endPoints } from 'api/endpoints';
import useDebounce from 'pages/reference-implementation/provisioning/RI004O/hooks/use-debounce';
import Btn from 'components/button';
import { useMutation } from 'react-query';
import axios from 'api/request';

const AzDataLake = (props) => {
    const { resource, handleDrawerAction, order } = props;

    const [formData, setFormData] = useState({
        name: resource?.configureData?.name || '',
    });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const ValidateAzDLApi = useMutation((payload) => axios.post(endPoints.riServices.RIIOTEVENTHUB.validateDLStorageAccName, payload));

    const debouncedInputValue = useDebounce(formData?.name, 500);

    const validateForm = () => {
        const newErrors = {};
        // Validate serviceBus Name
        if (!formData.name && !errors?.name) {
            newErrors.name = { type: 'required', message: 'Name is required' };
        }
        if (formData.name && errors?.name) {
            newErrors.name = { type: 'required', message: errors?.name?.message };
        }

        setErrors(newErrors);
        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    const handleOnSelectChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
        });
        setTouched({
            ...touched,
            [key]: true,
        });
    };

    const handleSubmit = () => {
        // Set all fields as touched to trigger validation errors display
        setTouched({
            name: true,
        });
        const checkFormValid = validateForm();
        if (!ValidateAzDLApi?.isLoading) handleDrawerAction('save', formData, checkFormValid);
    };

    const shouldShowError = (field) => {
        return touched[field] && !!errors[field];
    };

    const saveButton = () => (
        <Btn className="custom-btn" variant="contained" color="cmpPrimary" onClick={() => handleSubmit()} data-testid="save-btn">
            Save
        </Btn>
    );
    const cancelButton = () => (
        <Btn
            className="custom-btn"
            sx={RIStyles.tags.resetBtn}
            variant="outlined"
            color="cmpPrimary"
            onClick={() => handleDrawerAction('cancel')}
            data-testid="cancel-btn"
        >
            Cancel
        </Btn>
    );

    useEffect(() => {
        if (Object.keys(touched).length > 0) {
            validateForm();
        }
    }, [formData]);

    useEffect(() => {
        if (ValidateAzDLApi?.data?.isSuccess === true) {
            setErrors({ ...errors, name: '' });
        }
        if (ValidateAzDLApi?.data?.isSuccess === false) {
            setErrors({ ...errors, name: { type: 'required', message: ValidateAzDLApi?.data?.messages[0] } });
        }
    }, [ValidateAzDLApi?.isSuccess]);

    useEffect(() => {
        if (debouncedInputValue && debouncedInputValue !== null && debouncedInputValue !== '' && debouncedInputValue !== undefined) {
            ValidateAzDLApi.mutate({
                sector: order?.sector,
                environment: order?.environment,
                catalogServiceCode: 'RI004',
                division: order?.division || null,
                applicationRegion: order?.region || null,
                name: debouncedInputValue,
            });
        }
    }, [debouncedInputValue]);

    return (
        <Box style={RIStyles.tags.wrapper}>
            <Box sx={RIStyles.tags.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CssTextField
                            size="small"
                            fullWidth
                            autoComplete="off"
                            placeholder="Name"
                            label="Name *"
                            value={formData?.name || ''}
                            onChange={(e) => handleOnSelectChange('name', e?.target?.value)}
                            className={shouldShowError('name') ? 'validate' : ''}
                            error={shouldShowError('name')}
                            helperText={shouldShowError('name') && errors.name.type === 'required' ? errors.name.message : ''}
                            InputProps={{
                                endAdornment: <>{ValidateAzDLApi?.isLoading && <CircularProgress color="inherit" size={16} />}</>,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className="footer" sx={RIStyles.tags.actionWrapper}>
                {saveButton()}
                {cancelButton()}
            </Box>
        </Box>
    );
};

AzDataLake.propTypes = {
    resource: PropTypes.any,
    handleDrawerAction: PropTypes.func,
    order: PropTypes.any,
};

AzDataLake.defaultProps = {
    resource: null,
    handleDrawerAction: PropTypes.func,
    order: {},
};

export default AzDataLake;
