import React from 'react';
import { Box } from '@mui/material';
import Select from 'components/select';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';
import DeleteIcon from '@mui/icons-material/Delete';
import { MdAdd } from 'react-icons/md';
import { addSonarQubeRepo, removeSonarQubeRepo, updateSonarQubeRepo } from 'pages/project-onboarding/store/selector';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const SonarQubeRepo = () => {
    const [order] = useRecoilState(OrderPayload);
    const addRepo = useSetRecoilState(addSonarQubeRepo);
    const removeRepo = useSetRecoilState(removeSonarQubeRepo);
    const updateRepo = useSetRecoilState(updateSonarQubeRepo);

    const checkDuplicateRepo = (repository) => {
        const codeRepository = order?.sonarQubeCodeRepoList?.map((codeRepo) => codeRepo?.repo);
        const duplicateRepository = codeRepository.filter((item, index) => codeRepository.indexOf(item) !== index);
        if (repository) {
            return duplicateRepository.indexOf(repository) > -1;
        }
        return false;
    };

    const handleOnSelectChange = (selected, index) => {
        updateRepo({ selected, index });
    };
    const getOptions = () => {
        return order?.sonarQubeRepoList?.filter((repo) => {
            return !order?.sonarQubeCodeRepoList?.find((item) => item?.repo === repo?.value);
        });
    };

    return (
        <Box sx={ProjectOnboardingStyles.codeRepoStyle.contentWrapper}>
            {order?.sonarQubeCodeRepoList?.map((codeRepo, index) => (
                <Box
                    display="flex"
                    flexDirection="row"
                    key={index.toString()}
                    sx={ProjectOnboardingStyles.codeRepoStyle.rowWrapper}
                    className="space-x-2 mb-5 animate-opacity"
                >
                    <Box display="flex" gap={1} sx={ProjectOnboardingStyles.codeRepoStyle.codeRepoWrapper}>
                        <Box display="flex" sx={{ width: '100%' }}>
                            <Select
                                dataTestId="codeRepo-dropdown"
                                label="Repository Name *"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        },
                                    },
                                }}
                                value={codeRepo?.repo || null}
                                options={getOptions()}
                                handleOnSelect={(value) => handleOnSelectChange(value, index)}
                                isRequired={checkDuplicateRepo(codeRepo?.repo)}
                                customValidate={checkDuplicateRepo(codeRepo?.repo) ? 'validate' : ''}
                            />
                        </Box>
                    </Box>
                    <Box sx={ProjectOnboardingStyles.codeRepoStyle.iconWrapper}>
                        <Box className="flex w-full ">
                            {index !== 0 && order?.sonarQubeCodeRepoList?.length > 1 && (
                                <Box sx={ProjectOnboardingStyles.codeRepoStyle.deleteWrapper}>
                                    <DeleteIcon
                                        sx={ProjectOnboardingStyles?.removeBtn}
                                        color="error"
                                        onClick={() => removeRepo(codeRepo)}
                                        className="animate-opacity"
                                    />
                                </Box>
                            )}
                            {index === order?.sonarQubeCodeRepoList.length - 1 && order?.sonarQubeCodeRepoList?.length < 10 ? (
                                <MdAdd
                                    style={ProjectOnboardingStyles.codeRepoStyle.add}
                                    onClick={() => addRepo({ count: 1 })}
                                    className="w-7 h-7 text-blue-base cursor-pointer"
                                />
                            ) : (
                                <span className="w-7 h-7" />
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default SonarQubeRepo;
