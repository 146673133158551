import { selector } from 'recoil';
import { OrderPayload } from './index';

export const addADGroup = selector({
    key: 'RI002O_addADGroup',
    get: ({ get }) => get(OrderPayload).adGroupList,
    set: ({ set, get }, count) => {
        const currentADGroupList = get(OrderPayload).adGroupList;
        let adGroupList = [null];
        adGroupList = [...currentADGroupList, ...Array(count.count).fill({ adGroupValue: null })];

        set(OrderPayload, {
            ...get(OrderPayload),
            adGroupList,
        });
    },
});

export const removeADGroup = selector({
    key: 'RI002O_removeADGroup',
    get: ({ get }) => get(OrderPayload).adGroupList,
    set: ({ set, get }, adGroupToRemove) => {
        const currentADGroupList = get(OrderPayload).adGroupList;
        const adGroupToRemoveIndex = currentADGroupList.findIndex((val) => val === adGroupToRemove);
        const adGroupList = [...currentADGroupList.slice(0, adGroupToRemoveIndex), ...currentADGroupList.slice(adGroupToRemoveIndex + 1)];
        set(OrderPayload, {
            ...get(OrderPayload),
            adGroupList,
        });
    },
});

export const updateADGroup = selector({
    key: 'RI002O_updateADGroup',
    get: ({ get }) => get(OrderPayload).adGroupList,
    set: ({ set, get }, params) => {
        const currentADGroupList = get(OrderPayload).adGroupList;
        if (params?.key === 'inputSelect') {
            const updatedADGroup = { ...currentADGroupList[params.index], adGroupValue: params.selectedValue.label };
            const adGroupList = [...currentADGroupList.slice(0, params?.index), updatedADGroup, ...currentADGroupList.slice(params?.index + 1)];
            set(OrderPayload, { ...get(OrderPayload), adGroupList });
        }
        if (params?.key === 'inputChange') {
            const updatedADGroup = { ...currentADGroupList[params.index], adGroupInputValue: params.selectedValue };
            const adGroupList = [...currentADGroupList.slice(0, params?.index), updatedADGroup, ...currentADGroupList.slice(params?.index + 1)];
            set(OrderPayload, { ...get(OrderPayload), adGroupList });
        }
    },
});

export const addReaderADGroup = selector({
    key: 'RI002O_addReaderADGroup',
    get: ({ get }) => get(OrderPayload).nonPrivilegedADGroupList,
    set: ({ set, get }, count) => {
        const currentReaderADGroupList = get(OrderPayload).nonPrivilegedADGroupList;
        let nonPrivilegedADGroupList = [null];
        nonPrivilegedADGroupList = [...currentReaderADGroupList, ...Array(count.count).fill({ readerValue: null })];

        set(OrderPayload, {
            ...get(OrderPayload),
            nonPrivilegedADGroupList,
        });
    },
});

export const removeReaderADGroup = selector({
    key: 'RI002O_removeReaderADGroup',
    get: ({ get }) => get(OrderPayload).nonPrivilegedADGroupList,
    set: ({ set, get }, adGroupToRemove) => {
        const currentReaderADGroupList = get(OrderPayload).nonPrivilegedADGroupList;
        const readerADGroupToRemoveIndex = currentReaderADGroupList.findIndex((val) => val === adGroupToRemove);
        const nonPrivilegedADGroupList = [
            ...currentReaderADGroupList.slice(0, readerADGroupToRemoveIndex),
            ...currentReaderADGroupList.slice(readerADGroupToRemoveIndex + 1),
        ];
        set(OrderPayload, {
            ...get(OrderPayload),
            nonPrivilegedADGroupList,
        });
    },
});

export const updateReaderADGroup = selector({
    key: 'RI002O_updateReaderADGroup',
    get: ({ get }) => get(OrderPayload).nonPrivilegedADGroupList,
    set: ({ set, get }, params) => {
        const currentReaderADGroupList = get(OrderPayload).nonPrivilegedADGroupList;
        if (params?.key === 'inputSelect') {
            const updatedReaderADGroup = { ...currentReaderADGroupList[params.index], readerValue: params.selectedValue.label };
            const nonPrivilegedADGroupList = [
                ...currentReaderADGroupList.slice(0, params?.index),
                updatedReaderADGroup,
                ...currentReaderADGroupList.slice(params?.index + 1),
            ];
            set(OrderPayload, { ...get(OrderPayload), nonPrivilegedADGroupList });
        }
        if (params?.key === 'inputChange') {
            const updatedReaderADGroup = { ...currentReaderADGroupList[params.index], readerInputValue: params.selectedValue };
            const nonPrivilegedADGroupList = [
                ...currentReaderADGroupList.slice(0, params?.index),
                updatedReaderADGroup,
                ...currentReaderADGroupList.slice(params?.index + 1),
            ];
            set(OrderPayload, { ...get(OrderPayload), nonPrivilegedADGroupList });
        }
    },
});

export const addContributorADGroup = selector({
    key: 'RI002O_addContributorADGroup',
    get: ({ get }) => get(OrderPayload).privilegedADGroupList,
    set: ({ set, get }, count) => {
        const currentContributorADGroupList = get(OrderPayload).privilegedADGroupList;
        let privilegedADGroupList = [null];
        privilegedADGroupList = [...currentContributorADGroupList, ...Array(count.count).fill({ contributorValue: null })];

        set(OrderPayload, {
            ...get(OrderPayload),
            privilegedADGroupList,
        });
    },
});

export const removeContributorADGroup = selector({
    key: 'RI002O_removeContributorADGroup',
    get: ({ get }) => get(OrderPayload).privilegedADGroupList,
    set: ({ set, get }, adGroupToRemove) => {
        const currentContributorADGroupList = get(OrderPayload).privilegedADGroupList;
        const contributorADGroupToRemoveIndex = currentContributorADGroupList.findIndex((val) => val === adGroupToRemove);
        const privilegedADGroupList = [
            ...currentContributorADGroupList.slice(0, contributorADGroupToRemoveIndex),
            ...currentContributorADGroupList.slice(contributorADGroupToRemoveIndex + 1),
        ];
        set(OrderPayload, {
            ...get(OrderPayload),
            privilegedADGroupList,
        });
    },
});

export const updateContributorADGroup = selector({
    key: 'RI002O_updateContributorADGroup',
    get: ({ get }) => get(OrderPayload).privilegedADGroupList,
    set: ({ set, get }, params) => {
        const currentContributorADGroupList = get(OrderPayload).privilegedADGroupList;
        if (params?.key === 'inputSelect') {
            const updatedContributorADGroup = { ...currentContributorADGroupList[params.index], contributorValue: params.selectedValue.label };
            const privilegedADGroupList = [
                ...currentContributorADGroupList.slice(0, params?.index),
                updatedContributorADGroup,
                ...currentContributorADGroupList.slice(params?.index + 1),
            ];
            set(OrderPayload, { ...get(OrderPayload), privilegedADGroupList });
        }
        if (params?.key === 'inputChange') {
            const updatedContributorADGroup = { ...currentContributorADGroupList[params.index], contributorInputValue: params.selectedValue };
            const privilegedADGroupList = [
                ...currentContributorADGroupList.slice(0, params?.index),
                updatedContributorADGroup,
                ...currentContributorADGroupList.slice(params?.index + 1),
            ];
            set(OrderPayload, { ...get(OrderPayload), privilegedADGroupList });
        }
    },
});
