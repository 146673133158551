export const platformManaged = 'EIAP';
export const selectedEnv = 'Development';
export const imageURL = '/static/images/optional-ri';
export const RI003_AKSNS = 'RI003-AKSNS';
export const AKSNS = 'AKSNS';
export const AZSERVICEBUS = 'AZSERVICEBUS';
export const AZCOSMOSTABLE = 'AZCOSMOSTABLE';
export const AZCOSMOSCASSANDRA = 'AZCOSMOSCASSANDRA';
export const AZCOSMOSMONGO = 'AZCOSMOSMONGO';
export const AZCOSMOSNOSQL = 'AZCOSMOSNOSQL';
export const AZCOSMOSGREMLIN = 'AZCOSMOSGREMLIN';
export const AZSQLPAAS = 'AZSQLPAAS';
export const AZCOSMOSPOSTGRE = 'AZCOSMOSPOSTGRE';
export const KAFKA = 'KAFKA';
export const RI003 = 'RI003';
export const AZ_DATA_EXP = 'AZDATAEXPLORER';
export const AZ_DATA_LAKE_STORAGE = 'STORAGEACCOUNT';
export default {};
