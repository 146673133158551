export const virtualServiceTypes = [
    {
        name: 'Virtual Service with single context path',
        code: 'SINGLE_VS',
    },
    {
        name: 'Virtual service with multiple context path',
        code: 'SINGLE_VS_WITH_DIFFERENT_PORT_AND_CONTEXT_PATH',
    },
    {
        name: 'Virtual service with multiple service and context path',
        code: 'MULTIPLE_VS_WITH_DIFFERENT_PORT_AND_CONTEXT_PATH',
    },
    {
        name: 'UPDATE VS',
        code: 'UPDATE_VS',
    },
];

export const getSelectedVSServiceTypeIndex = (order, vsTypes, selectedVS) => {
    return vsTypes?.find((vs) => vs?.label === order?.aksVirtualService?.[selectedVS]?.virtualServiceType);
};

export const getSelectedVSServiceTypeIndexBasedOnCloned = (vsTypes, clonedVS) => {
    return vsTypes?.find((vs) => vs?.label === clonedVS);
};

export const isDuplicateVirtualService = (order) => {
    const list = order?.aksVirtualService.filter((service) => service?.virtualServiceName !== null).map((service) => service?.virtualServiceName);
    const duplicateVirtualService = list.filter((item, index) => list.indexOf(item) !== index);

    return { vsLength: duplicateVirtualService.length > 0, duplicateVirtualService };
};

export const isDuplicateHostName = (order) => {
    const list = order?.aksVirtualService
        ?.map((service) => service?.hostNamesPrefix?.map((host) => host?.hostNamePrefix))
        .flat()
        ?.filter((item) => item !== null && item !== '');

    const duplicateHostName = list?.filter((item, index) => list?.indexOf(item) !== index && item !== null);

    return duplicateHostName?.length > 0;
};

export const isReadyToSave = (order, selectedVS, validForm, vsTypes) => {
    const hostNamesPrefixList = order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.map((item) => item?.hostNamePrefix);
    const duplicateHostNamesPrefix = hostNamesPrefixList?.filter((item, index) => hostNamesPrefixList?.indexOf(item) !== index);

    const emptyOrigin = order?.aksVirtualService?.[selectedVS]?.aksCors?.allowedOrigins?.some(
        (origin) => origin?.allowedOrigin === null || origin?.allowedOrigin === ''
    );

    const validateSaveCondition =
        !order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.serviceName ||
        !order?.aksVirtualService?.[selectedVS]?.virtualServiceName ||
        order?.aksVirtualService?.[selectedVS]?.hostNamesPrefix?.some((service) => service?.hostNamePrefix === null || service?.hostNamePrefix === '') ||
        (order?.aksVirtualService?.[selectedVS]?.customDomainSelected === 'Yes' &&
            (!order?.aksVirtualService?.[selectedVS]?.customDomain?.[0]?.name || order?.aksVirtualService?.[selectedVS]?.customDomain?.[0]?.name === '')) ||
        (order?.aksVirtualService?.[selectedVS]?.customDomainSelected === 'Yes' && validForm?.isInvalidCustomDomainName) ||
        validForm?.isInvalidServicePort ||
        validForm?.isInvalidContextPath ||
        duplicateHostNamesPrefix?.length > 0 ||
        (order?.aksVirtualService?.[selectedVS]?.corsPolicy === 'Yes' && emptyOrigin);

    if (getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value === virtualServiceTypes?.[0]?.code) {
        return (
            validateSaveCondition ||
            !order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort ||
            !order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.contextPath ||
            validForm?.isInvalidServiceName
        );
    }
    if (getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value === virtualServiceTypes?.[1]?.code) {
        const serviceValues = order?.aksVirtualService?.[selectedVS]?.aksService;
        const contextPathList = serviceValues?.map((item) => item?.contextPath);
        const duplicateContextPath = contextPathList?.filter((item, index) => contextPathList?.indexOf(item) !== index);

        return (
            validateSaveCondition ||
            !order?.aksVirtualService?.[selectedVS]?.aksService?.[0]?.servicePort ||
            order?.aksVirtualService?.[selectedVS]?.aksService?.some((service) => service?.contextPath === null) ||
            validForm?.isInvalidServiceName ||
            duplicateContextPath?.length > 0
        );
    }
    if (getSelectedVSServiceTypeIndex(order, vsTypes, selectedVS)?.value === virtualServiceTypes?.[2]?.code) {
        const { vsLength } = isDuplicateVirtualService(order);

        const serviceValues = order?.aksVirtualService?.[selectedVS]?.aksService;
        const serviceDetailsValues = serviceValues?.map((item) => item?.serviceName?.concat(item?.servicePort, item?.contextPath));
        const duplicateServiceDetailsValues = serviceDetailsValues?.filter((item, index) => serviceDetailsValues?.indexOf(item) !== index);

        return (
            validateSaveCondition ||
            order?.aksVirtualService?.[selectedVS]?.aksService?.some((service) => service?.contextPath === null) ||
            order?.aksVirtualService?.[selectedVS]?.aksService?.some((service) => service?.servicePort === null) ||
            order?.aksVirtualService?.[selectedVS]?.aksService?.some((service) => service?.serviceName === null) ||
            duplicateServiceDetailsValues?.length > 0 ||
            vsLength
        );
    }
    return true;
};

export const isReadyToSubmit = ({ order, divisionReq, appRegionReq, validForm, isRequired, validOwners }) => {
    const selectedCDArray = order?.aksVirtualService?.map((vs) => vs?.customDomainSelected);
    const customDomainArray = order?.aksVirtualService?.map((vs) => vs?.customDomainSelected === 'Yes' && vs?.customDomain?.[0]?.name === null);
    const isUpdateVSCustomDomainValid = order?.aksVirtualServiceUpdateCustomDomain.every((item) => item.name !== '' && item.aksIngressUrl !== '');

    const customDomainList = order?.aksVirtualServiceUpdateCustomDomain?.map((item) => item?.name);
    const aksUrlList = order?.aksVirtualServiceUpdateCustomDomain?.map((item) => item?.aksIngressUrl);

    return (
        !order?.applicationService ||
        !order?.sector ||
        !order?.environment ||
        (divisionReq && !order?.division) ||
        (appRegionReq && !order?.applicationRegion) ||
        !order?.aksCluster ||
        !order?.department ||
        !order?.aksNamespace ||
        validForm?.isInvalidAksNamespace ||
        !validForm?.isNamespaceExists ||
        order?.aksVirtualServiceRequestType === '' ||
        order?.aksVirtualServiceRequestType === null ||
        (validOwners !== undefined && !Object.values(validOwners).every((item) => item === true)) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && validForm?.isInvalidCustomDomainName) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && validForm?.isInvalidIngressUrl) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && !isUpdateVSCustomDomainValid) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && new Set(customDomainList).size !== customDomainList?.length) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' && new Set(aksUrlList).size !== aksUrlList?.length) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'create' && customDomainArray?.includes(true)) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'create' && order?.aksVirtualService?.map((vs) => !vs?.virtualServiceName)?.includes(true)) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'create' &&
            selectedCDArray?.includes('Yes') &&
            isRequired &&
            (!order?.costCenter || !order?.applicationOwner || !order?.certificateOwnerOne || !order?.certificateOwnerTwo)) ||
        (order?.aksVirtualServiceRequestType?.toLowerCase() === 'update' &&
            isRequired &&
            (!order?.costCenter || !order?.applicationOwner || !order?.certificateOwnerOne || !order?.certificateOwnerTwo))
    );
};

export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};

export const environmentConfig = {
    development: {
        regex: /^[a-z0-9-._]{3,15}\.dev\.(mypepsico|pepsico)\.com$/,
        message: 'Custom domain name is Invalid. Must be in the format: xxx.dev.mypepsico.com or xxx.dev.pepsico.com (where xxx is 3-15 characters)',
    },
    qa: {
        regex: /^[a-z0-9-._]{3,15}\.(qa|ite)\.(mypepsico|pepsico)\.com$/,
        message:
            'Custom domain name is Invalid. Must be in the format: xxx.qa.mypepsico.com, xxx.qa.pepsico.com, xxx.ite.pepsico.com  or xxx.ite.pepsico.com (where xxx is 3-15 characters)',
    },
    'pre-production': {
        regex: /^[a-z0-9-._]{3,15}\.(preprod|ite)\.(mypepsico|pepsico)\.com$/,
        message:
            'Custom domain name is Invalid. Must be in the format: xxx.preprod.mypepsico.com, xxx.preprod.pepsico.com, xxx.ite.mypepsico.com or xxx.ite.pepsico.com (where xxx is 3-15 characters)',
    },
    production: {
        regex: /^[a-z0-9-._]{3,15}\.(mypepsico|pepsico)\.com$/,
        message: 'Custom domain name is Invalid. Must be in the format: xxx.mypepsico.com or xxx.pepsico.com (where xxx is 3-15 characters)',
    },
};

export const validateCustomDomain = (name, environment) => {
    const config = environmentConfig[environment?.toLowerCase()];

    if (!config) {
        return {
            isValid: false,
            message: 'Invalid environment specified',
        };
    }

    const isValid = config.regex.test(name);
    return {
        isValid,
        message: isValid ? 'Valid Custom Domain Name' : config.message,
    };
};
