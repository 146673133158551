import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CssTextField } from 'components/select';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, isSonarQubeRepoAvailable } from 'pages/project-onboarding/store';
import { getExistingServices } from 'pages/project-onboarding/utils/getFunctions';
import HelpImg from 'pages/project-onboarding/components/helpImg';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import useDebounce from 'pages/myidm/components/hooks/use-debounce';
import { formatSector } from 'pages/project-onboarding/utils';

export const NewADOProjectName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [helperText, setHelperText] = React.useState(false);
    const [, setRepoAvailable] = useRecoilState(isSonarQubeRepoAvailable);
    const [validProjectName, setValidProjectName] = React.useState('');
    const [projectNameExists, setProjectNameExists] = React.useState(false);

    // const projectNameGetApi = useMutation((projectValue) => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.adoProjectName}/${projectValue}`));
    const projectNameGetApi = useMutation((projectValue) =>
        axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.adoProjectName}/${projectValue}?adoPlatform=${order?.adoPlatform}`)
    );
    const projectDetailsApi = useMutation((projectName) => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.Jwt?.adoProjectDetails}/${projectName}`));
    const sonarQubeCodeRepoApi = useMutation((payload) => axios.get(`${endPoints.day2Operation.ProjectOnboarding.Jwt.sonarQubeRepo}/${payload}`));
    const SecToolCodeRepoApi = useMutation((payload) => axios.get(`${endPoints.day2Operation.ProjectOnboarding.Jwt.securityToolRepo}/${payload}`));

    const debouncedInputValue = useDebounce(validProjectName, 500);

    const setProjectName = (projectName = null) => {
        setOrder({
            ...order,
            projectName,
        });
    };

    const resetOrder = (param) => {
        setOrder({
            ...order,
            adoProjectType: null,
            ...(param && {
                projectName: null,
                existingProjectName: null,
            }),
            serviceResources: {
                ...order.serviceResources,
                isProjectAdmin: false,
                isBoard: false,
                isRepos: false,
                isPipelines: false,
                isSonarQube: false,
                isArtifactory: false,
                isSecurityTools: false,
                isTestPlan: false,
            },
            isTouched: false,
        });
    };

    React.useEffect(() => {
        if (projectNameGetApi?.isSuccess) {
            if (
                projectNameGetApi?.data?.data?.value?.length > 0 &&
                // projectNameGetApi?.data?.data?.value?.[0] === `CMP-ADO-TEST_${order?.sector}_${debouncedInputValue}`
                projectNameGetApi?.data?.data?.value?.[0] === `${order?.sector}_${debouncedInputValue}`
            ) {
                resetOrder();
                setValidForm({ ...validForm, isProjectNameExists: true });
                setProjectNameExists(true);
            } else {
                setValidForm({ ...validForm, isProjectNameExists: false });
                setProjectNameExists(false);
            }
        } else {
            setValidForm({ ...validForm, isProjectNameExists: false });
            setProjectNameExists(false);
        }
    }, [projectNameGetApi?.isSuccess]);

    React.useEffect(() => {
        if (debouncedInputValue) {
            projectNameGetApi?.mutate(`${order?.sector}_${debouncedInputValue}`);
        }
    }, [debouncedInputValue]);

    React.useEffect(() => {
        if (
            order?.projectName &&
            order?.projectName !== null &&
            order?.projectName !== '' &&
            order?.projectName !== undefined &&
            !/^[a-zA-Z0-9_]{3,64}$/.test(order?.projectName)
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isProjectNameValid: true });
            resetOrder();
        } else {
            setProjectNameExists(false);
            setValidProjectName(order?.projectName);
            setHelperText(false);
            setValidForm({ ...validForm, isProjectNameValid: false });
        }
    }, [order?.projectName]);

    React.useEffect(() => {
        if (projectDetailsApi?.isSuccess) {
            if (projectDetailsApi?.data?.data) {
                setOrder({
                    ...order,
                    projectDetails: projectDetailsApi?.data?.data,
                    existingAdminMembers: projectDetailsApi?.data?.data?.projectAdmin?.memberList,
                    adoProjectType: order?.adoProjectType || 'Advanced',
                    existingADOServices: getExistingServices(projectDetailsApi?.data?.data),
                });
            }
        }
    }, [projectDetailsApi?.isSuccess]);

    React.useEffect(() => {
        if (sonarQubeCodeRepoApi?.isSuccess) {
            if (Object.hasOwn(sonarQubeCodeRepoApi?.data, 'data'))
                setOrder({
                    ...order,
                    sonarQubeRepoList: sonarQubeCodeRepoApi?.data?.data?.repoNames?.map((app) => ({ label: app, value: app })),
                });
            else {
                setRepoAvailable(false);
            }
        }
    }, [sonarQubeCodeRepoApi?.isSuccess]);

    React.useEffect(() => {
        if (SecToolCodeRepoApi?.isSuccess) {
            setOrder({
                ...order,
                secToolRepoList: SecToolCodeRepoApi?.data?.data?.repoNames?.map((app) => ({ label: app, value: app })),
            });
        }
    }, [SecToolCodeRepoApi?.isSuccess]);

    React.useEffect(() => {
        if (order?.isClarityId === 'yes' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null) {
            if (!order?.serviceResources?.isSonarQube) {
                sonarQubeCodeRepoApi.mutate(order?.clarityDetails?.devopsProject);
            }
            if (!order?.serviceResources?.isSecurityTools) {
                SecToolCodeRepoApi.mutate(order?.clarityDetails?.devopsProject);
            }
            projectDetailsApi?.mutate(order?.clarityDetails?.devopsProject);
        }
    }, [order?.clarityDetails]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.projectName) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.projectName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.projectName, order?.isTouched]);

    const getHelperText = () => {
        if (helperText) return <span>Project Name should be min 3 chars and can contain only underscore(_) as special char.</span>;
        if (projectNameExists) return <span>Project Name already exists.</span>;
        return '';
    };

    React.useEffect(() => {
        if (!order?.clarityDetails) {
            resetOrder('resetProject');
        }
    }, [order?.clarityDetails]);

    return (
        <Box display="flex" sx={{ width: '100%' }} marginRight={3.4}>
            {(order?.adoPlatform === 'Sandbox/Personal' ||
                (order?.adoPlatform === 'Production' &&
                    ((order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
                        (order?.isADOProject === 'no' &&
                            order?.isClarityId === 'yes' &&
                            order?.clarityDetails &&
                            (typeof order?.clarityDetails?.devopsProject === 'undefined' ||
                                order?.clarityDetails?.devopsProject === null ||
                                order?.clarityDetails?.devopsProject === undefined))))) && (
                <CssTextField
                    data-testid="sector-projectName-text"
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('70%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& .MuiInputBase-root': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                        '& .MuiFormLabel-root': {
                            color: '#0047BA !important',
                        },
                    }}
                    disabled
                    autoComplete="off"
                    label="ADO Project Name *"
                    value={`${formatSector(order?.sector)}_` || ''}
                />
            )}
            {order?.adoPlatform === 'Production' && order?.isClarityId === 'yes' && order?.clarityDetails && order?.clarityDetails?.devopsProject !== null ? (
                <CssTextField
                    data-testid="clarity-projectName-text"
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& label.MuiInputLabel-shrink': {
                            color: '#0047BA !important',
                        },
                    }}
                    disabled
                    autoComplete="off"
                    label="ADO Project Name *"
                    value={order?.existingProjectName || ''}
                />
            ) : (
                <CssTextField
                    data-testid="projectName-text"
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                        '& .MuiInputBase-root': {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        },
                    }}
                    label={
                        order?.adoPlatform === 'Sandbox/Personal' ||
                        (order?.isADOProject === 'no' && order?.isClarityId === 'no') ||
                        (order?.isADOProject === 'no' && order?.isClarityId === 'yes' && order?.clarityDetails && order?.clarityDetails?.devopsProject === null)
                            ? ''
                            : 'ADO Project Name *'
                    }
                    disabled={
                        !order?.adoPlatform ||
                        (order?.adoPlatform === 'Production' &&
                            (order?.isADOProject !== 'no' ||
                                (order?.isADOProject === 'no' && !order?.isClarityId) ||
                                (order?.isADOProject === 'no' && order?.isClarityId === 'yes' && !order?.clarityDetails)))
                    }
                    autoComplete="off"
                    error={helperText || projectNameExists}
                    helperText={getHelperText()}
                    value={order?.projectName || ''}
                    onChange={(e) => setProjectName(e.target.value)}
                    className={validateInput()}
                />
            )}
            <Box ml={1} marginTop="6px">
                <HelpImg
                    maxWidth="500px"
                    title={
                        <>
                            <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                                <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                When creating an <b>Azure DevOps (ADO)</b> project, please adhere to the following guidelines:
                                            </Typography>
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Avoid spaces or any special characters</b> in the project
                                                name.
                                            </Typography>
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                <span style={{ fontSize: '8px' }}>&#9899;</span> <b>Use camel case</b> with an underscore (_) between words.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle}>
                                                Remember that <b>only names up to 64 characters</b> in length can be accepted.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    }
                />
            </Box>
        </Box>
    );
};

export default React.memo(NewADOProjectName);
